<template>
  <div>
    <div id="output" style="text-align: left">
      <div
        v-if="
          nfcReaderState == NfcState.READING ||
          nfcReaderState == NfcState.WRITING
        "
      >
        <div>
          <font-awesome-icon icon="fa-brands fa-nfc-symbol" fade="" size="6x" />
        </div>
        <div>
          <p v-if="nfcReaderState == NfcState.READING">
            {{ $t("nfc.ready_to_read") }}
          </p>
          <p v-else-if="nfcReaderState == NfcState.WRITING">
            {{ $t("nfc.ready_to_write") }}<br />
            {{ $t("nfc.if_write_error") }}
          </p>
          <p v-if="nfcMessage">{{ nfcMessage }}</p>
        </div>
      </div>
    </div>
    <div v-if="state == states.ERROR">Error please reload the page</div>
    <!-- Initial state -->
    <div v-else-if="state === states.INIT">
      <b-spinner></b-spinner> {{ $t("nfc.load_premises_tag_info") }}
    </div>
    <!-- Reading the tag -->
    <div v-else-if="state === states.PREMISE_TAG">
      <div v-if="tagId && !isTagEqual">
        <p>
          {{ $t("nfc.tag_difference") }}
        </p>
        <p v-if="tagId">{{ $t("nfc.tag_sn") }}: {{ tagId }}</p>
        <p v-if="tagId">{{ $t("nfc.premises_sn") }}: {{ premise_tag }}</p>
        <p v-if="message">
          <span v-if="!isTagEqual && !isAssociatingTag">
            {{ $t("nfc.linking_required") }}
            <b-button @click="updateTagAssociation"
              ><font-awesome-icon icon="fa-solid fa-link" />
              {{ $t("nfc.link_tag") }}
            </b-button>
          </span>
          <span v-else-if="!isTagEqual && isAssociatingTag">
            <b-spinner></b-spinner> {{ $t("nfc.linking_processing") }}
          </span>
        </p>
      </div>
    </div>
    <div v-else-if="state == states.READY_TO_WRITE_TAG">
      <!-- Tag is read -->
      <div v-if="tagId && isTagEqual">
        <p>
          <b>{{ $t("nfc.last_visits") }}</b>
        </p>
        <ul v-if="visits">
          <li v-for="visit in visits" :key="visit.type">
            {{
              visit.type == InterventionType.Maintenance
                ? $t("nfc.maintenance")
                : $t("nfc.intervention")
            }}: {{ format(visit.start, "dd/MM/yyyy HH:mm") }} -
            {{ format(visit.end, "dd/MM/yyyy HH:mm") }}
          </li>
        </ul>

        <p>
          <b>{{ $t("nfc.intervention_register") }}</b>
        </p>
        <b-form-group
          v-slot="{ ariaDescribedby }"
          :label="$t('nfc.intervention_type')"
        >
          <b-form-radio-group
            id="radio-group-1"
            v-model="interType"
            :options="interTypeOptions"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group :label="$t('nfc.arrival_hour')">
          <b-form-timepicker v-model="arrival" locale="fr"></b-form-timepicker>
        </b-form-group>
        <b-form-group :label="$t('nfc.departure_hour')">
          <b-form-timepicker
            v-model="departure"
            locale="fr"
          ></b-form-timepicker>
        </b-form-group>

        <b-button
          :disabled="!hasNFC && !arrival && !departure"
          @click="writeTagClick"
        >
          {{ $t("nfc.write") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { getPremisesTag, registerTagToPremise } from "@/_services/nfc.service";
import { format } from "date-fns";
import {
  createDateFromTimeInput,
  InterventionType,
  VisitMessage,
  VisitTag,
} from "@/_helpers/visits";
import { NfcState } from "@/_store/nfc.module";

enum States {
  INIT,
  PREMISE_TAG,
  ASSOCIATING_TAG,
  READ_TAG,
  READY_TO_WRITE_TAG,
  WRITE_TAG,
  ERROR,
}

// arrival departure
const interType = InterventionType.Maintenance;
const interTypeOptions = [
  { text: "Entretien", value: InterventionType.Maintenance },
  { text: "Dépannage", value: InterventionType.Repair },
];

export default defineComponent({
  name: "NFCRegister",
  props: {
    premises: {
      type: Object,
      default: null,
    },
  },
  emits: ["tagWritten"],
  data() {
    return {
      arrival: format(new Date(), "HH:mm"),
      departure: format(new Date(), "HH:mm"),
      interType: interType,
      interTypeOptions: interTypeOptions,
      premise_tag: "",
      // visitManager: new VisitManager(),
      state: States.INIT as States,
      isAssociatingTag: false,
      states: States,
      visits: [] as Array<VisitMessage>,
      nfcMessage: "",
    };
  },
  computed: {
    NfcState() {
      return NfcState;
    },
    ...mapState("NFCModule", {
      hasNFC: "hasNFC",
      nfcReaderState: "state",
      message: "message",
      tagId: "tagId",
      scanDate: "scanDate",
    }),
    InterventionType() {
      return InterventionType;
    },
    States() {
      return States;
    },
    isTagEqual(): boolean {
      console.log("isTagEqual", this.tagId, this.premise_tag);
      /** Check if the tag is the same as the premises tag */
      if (this.tagId === this.premise_tag) {
        return true;
      }
      return false;
    },
  },
  watch: {
    isTagEqual(val, oldVal) {
      console.log("isTagEqual", val, oldVal);
      if (val) {
        this.state = this.States.READY_TO_WRITE_TAG;
      }
    },
  },
  mounted: async function () {
    console.log("onMounted, Cleaning NFC messages");
    this.nfcMessage = "";
    this.state = States.INIT;
    this.$store.commit("NFCModule/clearNfcMessages");
    if (this.scanDate) {
      console.log("scanDate", this.scanDate);
      try {
        this.arrival = format(this.scanDate, "HH:mm");
      } catch (err) {
        console.log("err", err);
      }
    }
    await getPremisesTag(this.premises.id)
      .then((tag) => {
        // the tag is found
        console.log("tags", tag);
        this.premise_tag = tag.uid;
        this.state = States.PREMISE_TAG;
        this.readTagClick();
      })
      .catch((err) => {
        // 404 the tag is not associated to the premises
        console.log("err while loading tag data", err);
        this.premise_tag = "";
        this.state = States.PREMISE_TAG;
        this.readTagClick();
        // this.state = States.ERROR;
      });
  },
  methods: {
    ...mapActions("NFCModule", {
      readNfc: "readNfc",
      writeNfc: "writeNfc",
      stopNFC: "stopNfc",
    }),
    format,
    readTagClick: function () {
      console.log("readTagClick");

      this.readNfc().then((visit: VisitTag) => {
        console.log("visit", visit);
        if (visit.visits) {
          for (const visit1 of visit.visits.getVisits()) {
            console.log("visit1", visit1);
            this.visits.push(visit1);
          }
        }
        this.stopNFC();
      });
    },
    writeTagClick: function () {
      console.log(
        "writeTagClick",
        this.arrival,
        this.departure,
        this.interType
      );
      const arrivalDate = createDateFromTimeInput(new Date(), this.arrival);
      const departureDate = createDateFromTimeInput(new Date(), this.departure);
      const visit = new VisitMessage(
        arrivalDate,
        departureDate,
        this.interType
      );
      console.log("visit state", visit.toString());
      this.message.visits.addVisit(visit);
      console.log("visitManager", this.message.visits);
      this.state = this.states.WRITE_TAG;
      this.writeNfc(this.message.visits)
        .then((visit: VisitTag) => {
          console.log("visit", visit);
          this.$store.dispatch("NFCModule/clearScanDate");
          this.stopNFC();
          this.$emit("tagWritten", visit);
        })
        .catch((err) => {
          console.log("err while writing tag", err);
          // this.state = States.ERROR;
          this.nfcMessage = "Error while writing tag, please try again";
        });
    },
    updateTagAssociation: function () {
      console.log("updateTagAssociation");
      this.isAssociatingTag = true;
      registerTagToPremise(this.premises.id, this.tagId).then((response) => {
        if (response) {
          this.premise_tag = response;
        }
        console.log("registerTagToPremise done", response);
      });
    },
  },
});
</script>

<style scoped></style>
