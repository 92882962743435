<script lang="ts">
/**
 * This component  is used to scan NFC tags and to trigger a search in the Premises component.
 * If the tag is not associated to a Premise, the user is redirected to the Premises component.
 *
 * onMounted this start scanning a tag.
 * When the tag is read, the trigger a search in the Premises component, on the tag uuid.
 * onUnmounted this stop scanning a tag.
 */

import { defineComponent } from "vue";
import { mapState } from "vuex";
import { NfcState } from "@/_store/nfc.module";

export default defineComponent({
  name: "NFCScanner",
  emits: ["tagRead"],
  computed: {
    NfcState() {
      return NfcState;
    },
    ...mapState("NFCModule", ["hasNFC", "state"]),
  },
  mounted() {
    this.$store.dispatch("NFCModule/readNfc").then((tag) => {
      console.log("tag", tag);
      this.$store.dispatch("NFCModule/setScanDateNow");
      this.$emit("tagRead", tag);
      this.$store.dispatch("NFCModule/stopNfc");
    });
  },
  beforeUnmount() {
    this.$store.dispatch("NFCModule/stopNfc");
  },
  methods: {
    buttonClick() {
      // this.$store.dispatch("NFCModule/read");
    },
  },
});
</script>

<template>
  <b-container>
    <b-row>
      <div class="mx-auto">
        <div v-if="hasNFC" class="mx-auto">
          <!-- display NFC icon -->
          <font-awesome-icon icon="fa-brands fa-nfc-symbol" fade size="6x" />
          <div v-if="state == NfcState.IDLE">
            <p>Preparing to scan</p>
          </div>
          <div v-else-if="state == NfcState.READING">
            <p>{{ $t("nfc.waiting_for_tag") }}</p>
          </div>
          <div v-else>{{ $t("nfc.not_available") }}</div>
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<style scoped></style>
