import * as userService from "../_services";
import { router } from "@/_helpers";

const state = {
  status: "",
  items: [],
  itemsCount: 0,
  itemsFreshness: 0,
};

const actions = {
  getAll({ commit, dispatch }) {
    commit("getAllRequest");

    userService.getAll().then(
      (users) => {
        commit("getAllSuccess", users);
        dispatch("alert/success", router.app.$t("users.ok_message"), {
          root: true,
        });
      },
      (error) => {
        commit("getAllFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  delete({ commit }, id) {
    commit("deleteRequest", id);

    /*
    userService.delete(id).then(
      // eslint-disable-next-line no-unused-vars
      (user) => commit("deleteSuccess", id),
      (error) => commit("deleteFailure", { id, error: error.toString() })
    );
     */
  },
  clearAll({ commit }) {
    commit("clearAll");
  },
};

const mutations = {
  getAllRequest(state) {
    state.status = "loading";
  },
  getAllSuccess(state, items) {
    state.status = "success";
    state.items = items;
    state.itemsCount = items.length;
    state.itemsFreshness = Date.now();
  },
  getAllFailure(state) {
    state.status = "error";
  },
  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.items = state.items.map((user) =>
      user.id === id ? { ...user, deleting: true } : user
    );
  },
  deleteSuccess(state, id) {
    // remove deleted user from the store
    state.items = state.items.filter((user) => user.id !== id);
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.items = state.items.map((user) => {
      if (user.id === id) {
        // make a copy of user without 'deleting:true' property (see https://codeburst.io/use-es2015-object-rest-operator-to-omit-properties-38a3ecffe90)
        // eslint-disable-next-line no-unused-vars
        const { deleting, ...userCopy } = user;
        // return copy of user with 'deleteError:[error]' property
        return { ...userCopy, deleteError: error };
      }

      return user;
    });
  },
  clearAll(state) {
    state.items = [];
    state.itemsFreshness = 0;
    state.itemsCount = 0;
    state.status = "";
  },
};

const getters = {};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
