import { documentService } from "@/_services";
import { router } from "@/_helpers";

const state = {
  status: "",
  items: [],
  itemsCount: 0,
  itemsFreshness: 0,
};

const actions = {
  getAll({ dispatch, commit }, premiseId) {
    commit("getAllRequest");

    documentService.getAll({ premiseId: premiseId }).then(
      (data) => {
        commit("getAllSuccess", data.docs);
        dispatch("alert/success", router.app.$t("documents.ok_message"), {
          root: true,
        });
      },
      (error) => {
        commit("getAllFailure", error);
        if (error && error !== "Unauthorized") {
          dispatch("alert/error", error, { root: true });
        } else {
          dispatch("account/userDenied", "Documents", { root: true });
        }
      }
    );
  },
  clearAll({ commit }) {
    commit("clearAll");
  },
  fetching({ commit }) {
    commit("getAllRequest");
  },
  addAll({ dispatch, commit }, documents) {
    commit("getAllSuccess", documents);
  },
};

const getters = {
  getById: (state) => (id) => {
    return state.items.find((item) => item.id === id);
  },
};

const mutations = {
  clearAll(state) {
    state.status = "";
    state.items = [];
    state.itemsCount = 0;
    state.itemsFreshness = 0;
  },
  getAllRequest(state) {
    state.status = "loading";
  },
  getAllSuccess(state, items) {
    state.status = "success";
    state.items = items;
    state.itemsCount = items.length;
    state.itemsFreshness = Date.now();
  },
  getAllFailure(state, error) {
    state.status = "error";
    state.error = error;
  },
};

export const documents = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
