import { buildingService, premiseService } from "@/_services";
import { router } from "@/_helpers";

const state = {
  buildings: {},
};

const actions = {
  // Should be a getter ?
  async getBuildingDetailByID({ commit, state, dispatch }, buildingId) {
    let buildingDetail = null;
    if (!(buildingId in state.buildings)) {
      buildingDetail = await buildingService
        .getBuildingByID(buildingId)
        .then((building) => {
          commit("storeBuilding", building);
          return building;
          // return premisesData;
        });
    } else {
      buildingDetail = state.buildings[buildingId];
    }
    return buildingDetail;
  },
  async getBuildingDetailByURL({ commit, state, dispatch }, buildingURL) {
    const splitted = buildingURL.split("/");
    const id = splitted[splitted.length - 1];

    return await dispatch("getBuildingDetailByID", id);
  },
  clearAll({ commit }) {
    commit("clearBuildings");
  },
};

const mutations = {
  storeBuilding(state, building) {
    state.buildings[building.id] = building;
  },
  clearAll(state) {
    state.buildings = {};
  },
};

const getters = {
  getByID: (state) => (id) => {
    return state.buildings[id];
  },
};

export const buildings = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
