import { tenantService } from "@/_services";

const state = {
  status: "",
  items: [],
  // cache the tenants
  tenantStorage: {},
  itemsCount: 0,
  itemsFreshness: 0,
};

const actions = {
  getOne({ commit }, tenant) {
    commit("getOneSuccess", tenant);
  },
  addTenant({ commit }, tenant) {
    commit("addTenant", tenant);
  },
  clearAll({ commit }) {
    commit("clearAll");
  },
  async getTenantByURL({ commit, getters }, tenantURL) {
    let tenant = getters.getByURL(tenantURL);
    if (!tenant) {
      return await tenantService.getTenantByURL(tenantURL).then((tenant) => {
        commit("addTenant", tenant);
        return tenant;
      });
    }
    return tenant;
  },
  async getTenantByID({ commit, getters }, tenantID) {
    let tenant = getters.getByID(tenantID);
    if (!tenant) {
      return await tenantService.getTenantByID(tenantID).then((tenant) => {
        commit("addTenant", tenant);
        return tenant;
      });
    }
    return tenant;
  },
};

const mutations = {
  clearAll(state) {
    state.status = "";
    state.data = null;
    state.dataFreshness = 0;
  },
  getAllRequest(state) {
    state.status = "loading";
  },
  getAllSuccess(state, data) {
    state.status = "success";
    state.data = data;
    state.dataFreshness = Date.now();
  },
  getAllFailure(state, error) {
    state.status = "error";
    state.error = error;
  },
  addTenant(state, tenant) {
    state.items.push(tenant);
    state.tenantStorage[tenant.id] = tenant;
  },
  getOneSuccess(state, tenant) {
    state.status = "not_empty";
    const stillExists = state.items.some((item) => {
      return (
        `${item.first_name.toLowerCase()} ${item.last_name.toLowerCase()}` ===
        `${tenant.first_name.toLowerCase()} ${tenant.last_name.toLowerCase()}`
      );
    });
    if (!stillExists) {
      state.items = [...state.items, tenant];
    }
  },
};

const getters = {
  getByURL: (state, getters) => (url) => {
    const splittedURL = url.split("/");
    const id = splittedURL[splittedURL.length - 1];
    return getters.getByID(id);
  },
  getByID: (state) => (id) => {
    return state.tenantStorage[id];
  },
  getByName: (state) => (first_name, last_name) => {
    return state.items.find(
      (tenant) =>
        `${tenant.first_name.toLowerCase()} ${tenant.last_name.toLowerCase()}` ===
        `${first_name.toLowerCase()} ${last_name.toLowerCase()}`
    );
  },
  getName: (state, getters) => (first_name, last_name) => {
    const tenant = state.items.find(
      (tenant) =>
        `${tenant.first_name.toLowerCase()} ${tenant.last_name.toLowerCase()}` ===
        `${first_name.toLowerCase()} ${last_name.toLowerCase()}`
    );
    if (tenant) {
      return tenant.civility
        ? `${getters.getCivility(tenant.civility)} ${tenant.first_name} ${
            tenant.last_name
          }`
        : `${tenant.first_name} ${tenant.last_name}`;
    }
    return null;
  },
  getNameAndDetails: (state, getters) => (first_name, last_name) => {
    const tenant = state.items.find(
      (tenant) =>
        `${tenant.first_name.toLowerCase()} ${tenant.last_name.toLowerCase()}` ===
        `${first_name.toLowerCase()} ${last_name.toLowerCase()}`
    );
    if (tenant) {
      const tenantName = getters.getName(first_name, last_name);
      if (tenantName) {
        return `${tenantName}${tenant.email ? `, ${tenant.email}` : ""}${
          tenant.phones ? `, ${tenant.phones}` : ""
        }${tenant.mobiles ? `, ${tenant.mobiles}` : ""}`;
      }
      return `${first_name} ${last_name}`;
    }
    return null;
  },
  // eslint-disable-next-line no-unused-vars
  getCivility: (state) => (civility) => {
    return {
      1: "M.",
      2: "Mme",
      3: "Mlle",
      4: "Collectivité",
      5: "Etablissement public",
      6: "Société",
      7: "Société Individuelle",
      8: "Association",
      9: "Etat",
      10: "Dr",
    }[civility];
  },
};

export const tenants = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
