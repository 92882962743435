import { latLng } from "leaflet";

const state = {
  geolocationSupported: "geolocation" in navigator,
  enabled: false,
  // coordinates: latLng(44.9, 4.9),
  error: null,
  latitude: null,
  longitude: null,
  accuracy: null,
  radius: 100,
  maxRadius: 500,
  watcher: null,
};

const actions = {
  toggleGPS: ({ dispatch, getters }) => {
    if (!getters.isGeoLocationSupported) {
      return;
    }
    if (getters.isGeoLocationEnabled) {
      dispatch("disableLocation");
    } else {
      dispatch("enableLocation");
    }
  },
  enableLocation: ({ dispatch, commit, getters, state }) => {
    if (!getters.isGeoLocationSupported) {
      return;
    }
    if (state.watcher !== null && state.watcher !== undefined) {
      // console.log("Clearing watcher");
      navigator.geolocation.clearWatch(state.watcher);
      state.watcher = null;
    }
    if (
      state.enabled &&
      state.watcher !== null &&
      state.watcher !== undefined
    ) {
      return;
    }
    commit("resetLocation");
    if (
      "geolocation" in navigator &&
      "watchPosition" in navigator.geolocation
    ) {
      state.watcher = navigator.geolocation.watchPosition(
        (position) => dispatch("updateLocation", position),
        (error) => dispatch("updateError", error),
        {
          enableHighAccuracy: true,
          timeout: 15000,
          maximumAge: 180 * 1000,
        }
      );
      commit("setLocationState", true);
    }
  },
  disableLocation: ({ commit, getters, state }) => {
    if (!getters.isGeoLocationSupported) {
      return;
    }
    if (state.watcher) {
      try {
        navigator.geolocation.clearWatch(state.watcher);
      } catch (e) {
        console.log("Error uninstall location", e);
      } finally {
        state.watcher = null;
      }
    }
    commit("setLocationState", false);
    commit("resetLocation");
  },
  updateLocation: ({ commit }, position) => {
    commit("updatePosition", position);
  },
  updateError: ({ commit }, error) => {
    commit("updateError", error);
  },
};

const getters = {
  isGeoLocationSupported: (state) => {
    return state.geolocationSupported;
  },
  isGeoLocationEnabled: (state) => {
    return state.enabled;
  },
  isLocked: (state) => {
    if (!state.enabled) {
      return false;
    }
    if (state.accuracy == null) {
      return false;
    }
    return state.accuracy <= state.maxRadius;
  },
  getCoordinates: (state) => {
    if (!state.enabled) {
      return null;
    }
    return latLng(state.latitude, state.longitude);
  },
  getAccuracy: (state) => {
    return state.accuracy;
  },
};

const mutations = {
  setLocationState(state, locationState) {
    state.enabled = locationState;
  },
  resetLocation(state) {
    state.error = null;
    state.lattitude = null;
    state.longitude = null;
    state.accuracy = null;
  },
  updatePosition(state, position) {
    console.log("Update posiution called !", position);
    state.error = null;
    if (position !== undefined && position !== null) {
      state.latitude = position.coords.latitude;
      state.longitude = position.coords.longitude;
      state.accuracy = position.coords.accuracy;
    }
  },
  updateError(state, error) {
    state.error = error.code;
  },
};

export const geolocation = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
