/*
 * Documents services
 */

import { backendConfig } from "@/_helpers";
import { _axios } from "@/plugins/axios";

export const documentService = {
  getAll,
};

function getAll(filter) {
  let params = {};
  if (filter) {
    if ("premiseId" in filter && filter.premiseId) {
      params.premise = filter.premiseId;
    } else {
      params = filter;
    }
  }
  // console.log("[documents] Filtering parameters", params);

  return _axios
    .get(`${backendConfig.documentsEndpoint}`)
    .then((response) => response.data);
}
