import { locationService } from "@/_services";
import { router } from "@/_helpers";

const state = {
  status: "",
  data: [],
  dataFreshness: 0,
};

const actions = {
  async getAll({ dispatch, commit }) {
    commit("getAllRequest");
    try {
      if (state.data?.levels?.length > 0) {
        return state.data;
      } else {
        const tmpData = await locationService.getAll();
        console.log(tmpData);
        commit("getAllSuccess", tmpData);
        return tmpData;
      }
    } catch (error) {
      commit("getAllFailure", error);
      if (error && error !== "Unauthorized") {
        dispatch("alert/error", error, { root: true });
      }
    }
  },
  clearAll({ commit }) {
    commit("clearAll");
  },
};

const mutations = {
  clearAll(state) {
    state.status = "";
    state.data = [];
    state.dataFreshness = 0;
  },
  getAllRequest(state) {
    state.status = "loading";
  },
  getAllSuccess(state, data) {
    state.status = "success";
    state.data = data;
    state.dataFreshness = Date.now();
  },
  getAllFailure(state, error) {
    state.status = "error";
    state.error = error;
  },
};

const getters = {
  // All possible locations
  getLevels: (state) => () => {
    return state.data.levels;
  },
  getLevelsOptions: (state) => () => {
    return state.data.levels
      .map((level) => {
        return {
          text: level.name,
          value: level.id,
        };
      })
      .sort((elem1, elem2) => elem1.text.localeCompare(elem2.text));
  },
  getRooms: (state) => () => {
    return state.data.rooms;
  },
  getRoomsOptions: (state) => () => {
    return state.data.rooms
      .map((room) => {
        return {
          text: room.name,
          value: room.id,
        };
      })
      .sort((elem1, elem2) => elem1.text.localeCompare(elem2.text));
  },
  getLocationString: (state, getters) => (levelId, roomId, roomNumber) => {
    const level = levelId ? getters.getLevelById(levelId) : null;
    const room = roomId ? getters.getRoomById(roomId) : null;

    if (level && room) {
      if (roomNumber === null || roomNumber === "") {
        return `${room.name} ${level.name}`;
      } else {
        return `${room.name} ${level.name} n°${roomNumber}`;
      }
    } else if (level) {
      return level.name;
    } else if (room) {
      if (roomNumber !== null) {
        return `${room.name} n°${roomNumber}`;
      } else {
        return room.name;
      }
    }

    return ""; // router.app.$t("equipment.fields.unplaced");
  },
  getShortLocationString: (state, getters) => (levelId, roomId, roomNumber) => {
    const level = levelId ? getters.getLevelById(levelId) : null;
    const room = roomId ? getters.getRoomById(roomId) : null;

    if (level && room) {
      if (roomNumber !== null) {
        return `${room.short_name}${roomNumber}${level.short_name}`;
      } else {
        return `${room.short_name}${level.short_name}`;
      }
    } else if (level) {
      return level.short_name;
    } else if (room) {
      if (roomNumber !== null) {
        return `${room.short_name}${roomNumber}`;
      } else {
        return room.short_name;
      }
    }

    return ""; //router.app.$t("equipment.fields.unplaced");
  },
  getLevelById: (state) => (id) => {
    return state.data.levels.find((item) => item.id === id);
  },
  getRoomById: (state) => (id) => {
    return state.data.rooms.find((item) => item.id === id);
  },
};

export const locations = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
