import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

let plugins = [];
if (
  typeof process.env.VUE_APP_STORE_KEY === "undefined" ||
  (typeof process.env.VUE_APP_STORE_KEY !== "undefined" &&
    process.env.VUE_APP_STORE_KEY !== "")
) {
  const vuexStorage = new VuexPersistence({
    key: process.env.VUE_APP_STORE_KEY || "vizavy.store",
  });
  plugins.push(vuexStorage.plugin);
}
if (
  typeof process.env.VUE_APP_STORE_LOGGING !== "undefined" &&
  process.env.VUE_APP_STORE_LOGGING === "yes"
) {
  plugins.push(Vuex.createLogger());
}

import { alert } from "./alert.module";
import account from "./account.module";
// import apiconfig from "./___apiconfig.module.txt";
import { apiConfig } from "./apiConfig.module";
import { businesses } from "./businesses.module";
import { documents } from "./documents.module";
import { equipments } from "./equipments.module";
import { inventory } from "./inventory.module";
import { locations } from "./locations.module";
import { logs } from "./logs.module";
import { premises } from "./premises.module";
import { tenants } from "./tenants.module";
import { users } from "./users.module";
import { buildings } from "./buildings.module";
import { geolocation } from "./geoLocation.module";
import { NFCModule } from "./nfc.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    account,
    alert,
    apiConfig,
    businesses,
    documents,
    equipments,
    inventory,
    locations,
    logs,
    premises,
    tenants,
    users,
    buildings,
    geolocation,
    NFCModule,
  },
  plugins: plugins,
});
