<template>
  <viz-base-tile :id="id" :data="data" :premised="premised">
    <template #name>
      <cite>I am a Inventory widget #{{ id }} : {{ data }}</cite>
    </template>

    <template #body>
      <b-card-text>
        <em v-if="inventory.status === 'loading'">
          <font-awesome-icon icon="spinner" size="2x" spin></font-awesome-icon>
          &nbsp; {{ $t("actions.t_loading") }}
        </em>
        <div v-else-if="inventory.status === 'error'" class="m-3 bg-danger">
          <h1>Could not get inventory: {{ inventory.error }}!</h1>
        </div>
        <div v-else-if="inventory.status === 'success' && inventory.equipments">
          <ValidationObserver ref="form-observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(onSubmit)">
              <ValidationProvider name="f_equipment">
                <b-input-group class="mt-1">
                  <b-form-select
                    id="input-select-category"
                    v-model="chosenCategory"
                    :options="getPossibleCategories()"
                    size="sm"
                  >
                    <template #first>
                      <b-form-select-option value="null">{{
                        $t(`equipment.choose_category`)
                      }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    id="input-select-equipment"
                    v-model="chosenEquipment"
                    :options="equipmentsList"
                    size="sm"
                  >
                    <template #first>
                      <b-form-select-option value="null" disabled>{{
                        $t(`equipment.choose_equipment`)
                      }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-input-group-append>
                    <b-button
                      size="sm"
                      variant="warning"
                      :title="$t('premises.stop_inventory_premise')"
                      @click="creatingEquipment()"
                    >
                      <font-awesome-icon
                        aria-hidden="true"
                        icon="plus"
                        size="1x"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </ValidationProvider>
            </b-form>
          </ValidationObserver>
          <div
            v-if="displayedEquipments ? displayedEquipments.length > 0 : false"
          >
            <b-table
              :id="`table-inventory-equipments-${id}`"
              :sticky-header="data.tableHeaderCss"
              :caption="data.tableCaption"
              caption-top
              class="hey"
              primary-key="name"
              :items="displayedEquipments"
              :fields="fields"
              selectable
              select-mode="single"
              striped
              hover
              small
              :responsive="data.tableResponsive"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              style="cursor: pointer"
              :tbody-tr-class="rowClass"
              @row-clicked="rowClicked"
            >
              <template #cell(inventory_type)="data">
                <b-input-group class="mt-1">
                  <b-form-checkbox
                    :id="`inventoried-${data.item.id || data.item.name}`"
                    v-model="data.item.inventoried"
                    :name="`checkbox-${data.item.id || data.item.name}`"
                    switch
                    @change="checkEquipment(data.item, $event)"
                  ></b-form-checkbox>
                  <div v-if="data.item.inventory_type === 2">
                    <font-awesome-icon
                      :icon="['far', 'circle-check']"
                      class="present-equipment-icon"
                    />
                  </div>
                  <div v-else-if="data.item.inventory_type === 5">
                    <font-awesome-icon
                      icon="eye-slash"
                      class="not-visible-equipment-icon"
                    />
                  </div>
                  <div v-else-if="data.item.inventory_type === 3">
                    <font-awesome-icon
                      icon="ban"
                      class="absent-equipment-icon"
                    />
                  </div>
                  <div v-else-if="data.item.inventory_type === 4">
                    <font-awesome-icon
                      icon="trash-can"
                      class="deleted-equipment-icon"
                    />
                  </div>
                </b-input-group>
              </template>

              <!-- html content for the status -->
              <template #cell(type)="data">
                {{ data.item.type }}
              </template>

              <!-- html content for the status -->
              <template #cell(name)="data">
                {{ data.item.name }}
              </template>

              <!-- html content for the status -->
              <template #cell(status)="data">
                <StatusBox :state="data.value" ok-icon=""></StatusBox>
              </template>

              <!-- A custom formatted column -->
              <template #cell(location)="data">
                <strong class="text-primary">{{ data.item.type.name }}</strong>
                <span v-if="data.item.location">
                  <em>({{ data.item.location }})</em>
                </span>
                <span v-if="[2, 5].includes(data.item.inventory_type)">
                  <b-button
                    v-b-toggle="
                      `toggle-select-location-${data.item.id || data.item.name}`
                    "
                    size="sm"
                    variant="warning"
                    :title="$t('inventory.add_location_title')"
                    class="float-right"
                  >
                    <font-awesome-icon
                      icon="map-marker"
                      size="1x"
                    ></font-awesome-icon>
                  </b-button>
                  <b-collapse
                    :id="`toggle-select-location-${
                      data.item.id || data.item.name
                    }`"
                    class="mt-2"
                  >
                    <b-card>
                      <b-form-group
                        :id="`select-level-group-${
                          data.item.id || data.item.name
                        }`"
                        :label="$t('add_location_title')"
                        :label-for="`select-level-${
                          data.item.id || data.item.name
                        }`"
                      >
                        <b-form-select
                          :id="`select-level-${data.item.id || data.item.name}`"
                          v-model="data.item.chosenLevel"
                          :options="getLevelsOptions()"
                          :title="$t('inventory.add_location_title')"
                          size="sm"
                          class="float-right"
                          @change="settingLocation(data.item)"
                        >
                          <template #first>
                            <b-form-select-option :value="null"
                              >---</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        :id="`select-room--group-${
                          data.item.id || data.item.name
                        }`"
                        :label="$t('add_room_title')"
                        :label-for="`select-room-${
                          data.item.id || data.item.name
                        }`"
                      >
                        <b-form-select
                          :id="`select-room-${data.item.id || data.item.name}`"
                          v-model="data.item.chosenRoom"
                          :options="getRoomsOptions()"
                          :title="$t('inventory.add_room_title')"
                          size="sm"
                          class="float-right"
                          @change="settingLocation(data.item)"
                        >
                          <template #first>
                            <b-form-select-option :value="null"
                              >---</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>

                      <b-form-group
                        :id="`select-room--group-${
                          data.item.id || data.item.name
                        }`"
                        :label="$t('equipment.fields.p_roomnum')"
                        :label-for="`select-room-${
                          data.item.id || data.item.name
                        }`"
                      >
                        <b-form-select
                          id="input-room-number"
                          v-model="data.item.chosenNumber"
                          :options="numberList"
                          value-field="value"
                          :title="$t('equipment.fields.p_roomnum')"
                          text-field="text"
                          :placeholder="$t('equipment.fields.p_roomnum')"
                          @change="settingLocation(data.item)"
                          size="sm"
                        >
                          <template #first>
                            <b-form-select-option value="null" disabled>{{
                              $t(`equipment.fields.p_roomnum`)
                            }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-card>
                  </b-collapse>
                </span>
              </template>

              <!-- html content for the detailed row -->
              <template #row-details="row">
                <b-card>
                  <b-row v-if="[2, 5].includes(row.item.inventory_type)">
                    <b-col class="text-center">
                      <b-button
                        size="sm"
                        variant="secondary"
                        :title="$t('inventory.update_metadata_title')"
                        @click="editEquipment(row.item)"
                      >
                        <font-awesome-icon
                          icon="share"
                          size="1x"
                        ></font-awesome-icon
                        >&nbsp;{{ $t("inventory.update_metadata") }}
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-list-group flush>
                    <b-list-group-item
                      v-for="(value, key) in row.item"
                      v-if="isDisplayed(key) && value"
                      :key="key"
                    >
                      <b-row>
                        <b-col sm="4" class="text-sm-left">
                          <strong>{{ $t(`inventory.fields.${key}`) }}</strong>
                        </b-col>

                        <b-col
                          v-if="key === 'status'"
                          sm="4"
                          class="text-sm-left"
                        >
                          {{ value === true ? "Ok" : "Ko" }}</b-col
                        >

                        <b-col
                          v-else-if="key === 'location'"
                          sm="4"
                          class="text-sm-left"
                        >
                          {{ value }}</b-col
                        >

                        <b-col
                          v-else-if="key === 'usage'"
                          sm="4"
                          class="text-sm-left"
                        >
                          {{ getUsage(value) }}</b-col
                        >

                        <b-col
                          v-else-if="key === 'inventory_type'"
                          sm="4"
                          class="text-sm-left"
                        >
                          <span v-if="row.item.inventoried">{{
                            $t("inventory.inventoried")
                          }}</span
                          ><span v-else>{{
                            $t("inventory.not_inventoried")
                          }}</span>
                          -
                          {{ getInventoryType(value) }}
                        </b-col>

                        <b-col
                          v-else-if="key === 'in_service_date'"
                          sm="4"
                          class="text-sm-left"
                        >
                          {{ value }}
                        </b-col>

                        <b-col v-else sm="4" class="text-sm-left">
                          {{ value }}</b-col
                        >
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item
                      v-for="(value, key) in row.item.metadata"
                      v-if="value && getFormField(row.item, key) != null"
                      :key="key"
                    >
                      <b-row>
                        <b-col sm="4" class="text-sm-left">
                          <strong>{{ getFormDataLabel(row.item, key) }}</strong>
                        </b-col>
                        <b-col sm="4" class="text-sm-left">
                          {{ getFormDataValue(row.item, key, value) }}
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </template>
            </b-table>

            <b-pagination
              v-if="inventory.equipments.length > perPage"
              v-model="currentPage"
              :total-rows="equipmentsRows"
              :per-page="perPage"
              hide-goto-end-buttons
              size="sm"
              :aria-controls="`table-inventory-equipments-${id}`"
              align="right"
            ></b-pagination>
          </div>
          <div v-else class="text-warning">
            {{ $t("inventory.no_items") }} {{ displayedEquipments }}
          </div>
        </div>
        <div v-else class="text-warning">No known inventory!</div>
      </b-card-text>

      <!-- New equipment form modal -->
      <modal-equipment
        :modal-id="equipmentDialog.id"
        :modal-size="equipmentDialog.size"
        :modal-title="equipmentDialog.title"
        :chosen-equipment="equipmentDialog.equipment"
      >
      </modal-equipment>
      <b-modal
        ref="EquipmentInventoryDialog"
        :title="checkEquipmentTitle"
        :hide-footer="true"
        @close="closeCheckEquipment"
      >
        <p>{{ $t("inventory.modal_inventory.content") }}</p>
        <b-button
          class="m-2 present-equipment-btn"
          @click="setEquipmentPresent"
          >{{ $t("inventory.modal_inventory.present") }}</b-button
        >
        <b-button
          v-if="displayEquipmentBtnStatus(3)"
          class="m-2 absent-equipment-btn"
          @click="setEquipmentAbsent"
          >{{ $t("inventory.modal_inventory.absent") }}</b-button
        >
        <b-button
          v-if="displayEquipmentBtnStatus(3)"
          class="m-2 deleted-equipment-btn"
          @click="setEquipmentDeleted"
          >{{ $t("inventory.modal_inventory.deleted") }}</b-button
        >
        <b-button
          v-if="displayEquipmentBtnStatus(3)"
          class="m-2 not-visible-equipment-btn"
          @click="setEquipmentNotVisible"
          >{{ $t("inventory.modal_inventory.not_visible") }}</b-button
        >
      </b-modal>
    </template>
  </viz-base-tile>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { InventoryState, InventoryType } from "@/_services";
import { format } from "date-fns";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

export default {
  name: "VizInventory",
  components: {
    VizBaseTile: () => import("./BaseTile"),
    ModalEquipment: () => import("../ModalEquipment"),
    StatusBox: () => import("../StatusBox"),
  },
  props: {
    id: {
      type: String,
      default: "1",
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    premised: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberList: [
        { value: "", text: "Sans numéro" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7, text: "7" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10" },
      ],
      // The list of the displayed field in the details pane
      displayedFields: [
        "inventory_type",
        // "status",
        "name",
        "usage",
        "brand",
        "in_service_date",
        "removed_date",
        "inventory_date",
      ],
      sortBy: "type",
      sortDesc: false,
      // The list of most useful fields
      fields: [
        {
          key: "inventory_type",
          label: this.$t("inventory.fields.inventory_type"),
          sortable: true,
        },
        // todo: equipment current status, as of now (2020/11/25) disabled. Uncomment to display
        // {
        //   key: "status",
        //   label: this.$t("inventory.fields.status"),
        // },
        {
          key: "type",
          label: this.$t("inventory.fields.type"),
          sortable: true,
        },
        /*{
          key: "name",
          label: this.$t("inventory.fields.name"),
          sortable: true,
        },*/
        {
          key: "location",
          label: this.$t("inventory.fields.location"),
          sortable: true,
        },
      ],
      // The inventory filtering
      chosenCategory: null,
      chosenEquipment: null,
      equipmentDialog: {
        id: "equipment-modal",
        size: "xl",
        title: "",
        equipment: null,
      },
      chosenLevel: null,
      chosenRoom: null,
      chosenLocation: null,
      checkEquipmentTitle: "",
    };
  },
  computed: {
    ...mapGetters("account", { company: "company" }),
    ...mapGetters("inventory", [
      // "getPresence",
      "getUsage",
      "getInventoryType",
      "displayedEquipments",
    ]),
    ...mapGetters("equipments", [
      "getPossibleCategories",
      "getPossibleEquipments",
      "getEquipmentData",
      "getEquipmentForm",
      "getCategoryById",
    ]),
    ...mapGetters("locations", [
      "getLevels",
      "getLevelsOptions",
      "getRooms",
      "getRoomsOptions",
    ]),
    ...mapState({
      locations: (state) => state.locations,
      inventory: (state) => state.inventory,
      chosenPremise: (state) => state.premises.selected,
    }),
    equipmentsList() {
      return this.chosenCategory
        ? this.getPossibleEquipments(this.getCategoryById(this.chosenCategory))
        : this.getPossibleEquipments();
    },
    equipmentsRows() {
      return this.inventory.equipments.length;
    },
    selectedEquipment() {
      return this.chosenEquipment;
    },
  },
  watch: {
    // Changed the premise to inventory
    chosenPremise: function (newVal, oldVal) {
      if (!this.premised) {
        return;
      }

      if (newVal === null) {
        // No more selected premise
        // console.log("[inventory] no more selected premise");
        // this.clearAll();
      } else {
        // Newly selected premise
        // Get all the available information for the inventory of the selected premise
        console.log("[inventory] fetching...");
        const premisesCategory = this.chosenPremise.category;
        this.getBusinessForm({
          businessId: this.chosenPremise.business_id,
          premisesCategory: premisesCategory,
        }).then((forms) => {
          console.log("Got forms ? ", forms);
          this.setForms(forms);

          // Inventory id is an empty string!
          const syncGetInventory = async (premiseId) => {
            await this.getInventory(premiseId, "");
            console.log("Données récupérées");
            if (this.chosenPremise.features === undefined) {
              this.chosenPremise.features = this.getEquipmentForm(
                this.chosenPremise.type_id
              );
            }
            // Check inventory_mode
            if (
              this?.chosenPremise?.inventory_mode === "REQUIRE_ALL_EQUIPMENT"
            ) {
              const possibleEquipments = this.getPossibleEquipments();
              const displayedEquipmentsIds = this.displayedEquipments.map(
                (item) => item?.type_id
              );
              // Display all possible equipments
              possibleEquipments.forEach((item) => {
                if (displayedEquipmentsIds.indexOf(item.value) < 0) {
                  const tmpCreated = this.creatingEquipment(false, item.value);
                  const metadata = {};
                  for (const field of tmpCreated.features.fields) {
                    if (field.inputValue) {
                      metadata[field.name] = field.inputValue;
                      // update the input value of the field
                      tmpCreated.metadata[field.name] = field.inputValue;
                    }
                  }
                  this.createNewEquipment(tmpCreated);
                }
              });
            }
          };
          syncGetInventory(this.chosenPremise.id);
        });
      }
    },
  },
  created() {
    console.log(
      `[inventory] Premised: ${this.premised}, chosen: '${
        this.chosenPremise && this.chosenPremise.name
      }'`
    );
    // Get all equipments types
    this.getEquipmentsTypes();

    // Get all equipments forms data
    // this.getEquipmentsForms(this.chosenPremise.business_id);

    // Get all inventory possible locations
    this.getLocations();

    if (this.premised && this.chosenPremise) {
      // Get all the available information for the inventory of the selected premise
      this.getInventory(this.chosenPremise.id, "");
    }

    // Event handler for the equipments creation / update
    this.$root.$on("equipment-created", () => {
      // console.log("[inventory] equipment created");

      // Get all the available information for the inventory of the selected premise
      // this.getInventory(this.chosenPremise.id, "");
    });

    this.$root.$on("equipment-updated", (equipmentId) => {
      // console.log("[inventory] equipment updated", equipmentId);
    });
  },
  methods: {
    ...mapActions("inventory", {
      addEquipmentToInventory: "addEquipment",
      updateEquipmentInInventory: "updateEquipment",
      getInventory: "getInventory",
      updateEquipment: "updateEquipment",
      getBusinessForm: "getBusinessForm",
    }),
    ...mapActions("locations", { getLocations: "getAll" }),
    ...mapActions("equipments", {
      updateEquipment: "update",
      getEquipmentsTypes: "getTypes",
      setForms: "setForms",
    }),
    rowClass(item) {
      if (!item) return;
    },
    isDisplayed(key) {
      return this.displayedFields.indexOf(key) >= 0;
    },
    onSubmit() {
      // console.log("Submitted...");
    },
    createNewEquipment(equipment) {
      const equipmentName = this.createEquipmentName(equipment);
      const payload = {
        name: equipmentName,
        inventoried: false,
        displayed: true,
        type: equipment.type,
        features: equipment.features,
        agency: equipment?.premise?.agency,
        business: equipment?.premise?.business,
        building: equipment?.premise?.building?.name,
        premises: equipment?.premise?.name,
        location: "",
        inventory_type: InventoryType.NEW,
        inventory_state: InventoryState.NOT_VALIDATED,
        inventory_date: format(new Date(), "yyyy-MM-dd"),
        metadata: equipment.metadata,
        provider: this.company,
        contract_start_date: "2018-08-28",
        contract_end_date: "2023-09-30",
        new: true,
        type_id: equipment.type,
        type: equipment.features.name,
      };
      console.log("[equipment] creating equipment:", payload);
      this.addEquipmentToInventory(payload);
      this.$root.$emit("created-equipment");
    },
    editEquipment(equipment) {
      // console.log("Editing an equipment", equipment);
      equipment.new = false;
      equipment.features = this.getEquipmentForm(equipment.type_id);

      // Equipment modal props
      this.equipmentDialog.title = this.$t("equipment_title", {
        name: `${equipment.type} - ${equipment.location}`,
      });
      this.equipmentDialog.equipment = equipment;
      this.$bvModal.show(this.equipmentDialog.id);
    },
    createEquipmentName(equipment) {
      let short_name;
      if (equipment.features.equipment_type.short_name) {
        short_name = equipment.features.equipment_type.short_name;
      } else {
        short_name = equipment.features.type;
      }
      return `${equipment.premise.name}${short_name}`;
    },
    creatingEquipment(openModal = true, equipmentId = null) {
      const equipment = this.getEquipmentData(
        equipmentId === null ? this.chosenEquipment : equipmentId
      );
      if (!equipment) {
        return;
      }
      // console.log(
      //   "Creating an equipment",
      //   equipmentId,
      //   this.chosenCategory,
      //   this.chosenEquipment,
      //   equipment
      // );
      equipment.new = true;
      equipment.features = this.getEquipmentForm(
        equipmentId !== null ? equipmentId : this.chosenEquipment
      );
      equipment.technician = equipment.premise = this.chosenPremise;
      equipment.type = this.chosenEquipment || equipmentId;
      equipment.metadata = {};
      if (openModal) {
        // Equipment modal props
        this.equipmentDialog.equipment = equipment;
        this.equipmentDialog.title = this.$t("equipment_title", {
          name: equipment.name,
        });
        this.$bvModal.show(this.equipmentDialog.id);
      } else {
        return equipment;
      }
    },
    async settingLocation(item) {
      item._status = "updatable";
      const locations = await this.getLocations();
      const tmpChosenLevel = locations?.levels?.filter(
        (level) => level.id === item.chosenLevel
      );
      const tmpChosenRoom = locations?.rooms?.filter(
        (room) => room.id === item.chosenRoom
      );
      item.location = `${
        tmpChosenRoom[0]?.short_name ? tmpChosenRoom[0]?.name + " " : ""
      }${tmpChosenLevel[0]?.short_name ? tmpChosenLevel[0]?.name : ""}${
        item?.chosenNumber ? " n°" + item?.chosenNumber : ""
      }`;
      item.location_updated = true;
    },
    rowClicked(item) {
      item._showDetails =
        typeof item._showDetails === "undefined" ? true : !item._showDetails;
    },
    checkEquipment(item, checking) {
      console.log("Equipment is: ", item, checking);
      this.checkEquipmentTitle = this.$t("inventory.modal_inventory.title", {
        location: item.location,
        equipment_type: item.type,
      });
      if (!checking) {
        // item.inventory_type = InventoryType.MISSING;
        return;
      }
      this.getForm(item);
      this.chosenEquipment = item;
      this.$refs["EquipmentInventoryDialog"].show();
    },
    closeCheckEquipment() {
      // restore state
      this.chosenEquipment.inventoried = false;
      this.chosenEquipment = null;
    },
    setEquipmentPresent() {
      this.chosenEquipment.inventoried = true;
      this.chosenEquipment.inventory_type = InventoryType.CHECKED;
      this.chosenEquipment.present = true;
      this.$refs["EquipmentInventoryDialog"].hide();
      this.editEquipment(this.chosenEquipment);
      this.chosenEquipment = null;
    },
    displayEquipmentBtnStatus(value) {
      let display = false;
      this.chosenEquipment?.features?.types?.forEach((type) => {
        if (type.value === value) {
          display = true;
        }
      });
      return display;
    },
    setEquipmentAbsent() {
      this.chosenEquipment.inventoried = true;
      this.chosenEquipment.inventory_type = InventoryType.MISSING;
      this.chosenEquipment.present = true;
      this.chosenEquipment = null;
      this.$refs["EquipmentInventoryDialog"].hide();
    },
    setEquipmentNotVisible() {
      this.chosenEquipment.inventoried = true;
      this.chosenEquipment.inventory_type = InventoryType.NOT_VISIBLE;
      this.chosenEquipment.present = true;
      this.chosenEquipment = null;
      this.$refs["EquipmentInventoryDialog"].hide();
    },
    setEquipmentDeleted() {
      this.chosenEquipment.inventoried = true;
      this.chosenEquipment.inventory_type = InventoryType.DELETED;
      this.chosenEquipment.present = true;
      this.chosenEquipment = null;
      this.$refs["EquipmentInventoryDialog"].hide();
    },
    rowChecked(item) {
      item._showDetails = true;
      /*
      if (!item.inventoried) {
        item.inventory_type = InventoryType.MISSING;
      } else {
        if (item.present !== undefined) {
          if (item.present) {
            item.inventory_type = InventoryType.CHECKED;
          } else {
            item.inventory_type = InventoryType.DELETED;
          }
        }
      }

       */
      console.log("rowChecked", item);
    },
    getForm(equipment) {
      if (equipment.features === undefined || equipment.features == null) {
        equipment.features = this.getEquipmentForm(equipment.type_id);
        console.log(equipment);
      }
      if (equipment.features == null) {
        return null;
      }
      return equipment.features;
    },
    getFormField(equipment, name) {
      const fields = this.getForm(equipment)["fields"];
      //const fields = equipment.features["fields"];
      for (const field of fields) {
        // console.log(name, field["name"], field["name"] === name);
        if (field["name"] === name) {
          return field;
        }
      }
      return null;
    },
    getFormDataLabel(equipment, name) {
      // const fields = this.getForm(equipment)["fields"];
      const field = this.getFormField(equipment, name);
      if (field == null) {
        return name;
      }
      return field["label"];
    },
    getFormDataValue(equipment, name, value) {
      const field = this.getFormField(equipment, name);
      if (field == null) {
        return null;
      }
      if ("values" in field) {
        for (const field_value of field["values"]) {
          if (field_value["value"] === value) {
            return field_value["text"];
          }
        }
        return value;
      }
      return value;
    },
  },
};
</script>

<style>
.deleted-equipment-btn {
  background-color: #eb4d4b !important;
  color: white !important;
}

.deleted-equipment-btn:hover {
  color: #212529 !important;
  background: #e9ecef !important;
  border-color: #e9ecef !important;
}

.deleted-equipment-icon {
  color: #eb4d4b !important;
}

.present-equipment-btn {
  background-color: #6ab04c !important;
  color: white !important;
}

.present-equipment-btn:hover {
  color: #212529 !important;
  background: #e9ecef !important;
  border-color: #e9ecef !important;
}

.present-equipment-icon {
  color: #44bd32 !important;
}

.not-visible-equipment-btn {
  color: white !important;
  background: #718093 !important;
  border-color: #718093 !important;
}

.not-visible-equipment-btn:hover {
  color: #95afc0 !important;
  background: #e9ecef !important;
  border-color: #e9ecef !important;
}

.not-visible-equipment-icon {
  color: #718093 !important;
}

.absent-equipment-btn {
  color: white !important;
  background: #f0932b !important;
  border-color: #f0932b !important;
}

.absent-equipment-btn:hover {
  color: #212529 !important;
  background: #e9ecef !important;
  border-color: #e9ecef !important;
}

.absent-equipment-icon {
  color: #f0932b;
}
</style>

<i18n>
{
  "en": {
    "title": "Known inventory",
    "equipment_title": "Equipment information: {name}",
    "location_title": "New location inventory",
    "add_location_title": "Level",
    "add_room_title": "Room"
  },
  "fr": {
    "title": "Equipements connus",
    "equipment_title": "Informations de l'équipement : {name}",
    "location_title": "Recensement d'une nouvelle pièce",
    "add_location_title": "Niveau",
    "add_room_title": "Pièce"
  }
}
</i18n>
