/**
 * Service for the guest portal endpoint. Use the standard api.
 */
import { _axios } from "@/plugins/axios";

export const guestService = {
  getEvents,
  getNotices,
};

function getEvents(token) {
  const params = { token: token };
  return _axios
    .get(`/guests/future_events`, { params: params })
    .then((response) => response.data);
}

function getNotices(token) {
  const params = { token: token };
  return _axios
    .get(`/guests/notices`, { params: params })
    .then((response) => response.data);
}
