import { _axios } from "@/plugins/axios";
import {
  createDateFromTimeInput,
  InterventionType,
  VisitManager,
  VisitMessage,
  VisitTag,
} from "@/_helpers/visits";
import { store } from "@/_store";

export interface NFCReaderInterface {
  // onReading: (event: any) => void;
  // onReadingError: (event: any) => void;
  scan: () => Promise<VisitTag>;
  write: (message: VisitManager) => Promise<VisitTag>;
  cancel: () => void;
}

export class NFCReader implements NFCReaderInterface {
  private abortController: AbortController;
  private reader: NDEFReader;
  private isWriting: boolean;
  public constructor() {
    this.abortController = new AbortController();
    this.reader = new NDEFReader();
    this.isWriting = false;
  }

  cancel() {
    try {
      this.abortController.abort();
      console.log("Abort NFC reading");
    } catch (e) {
      console.log("Error while aborting NFC reading", e);
    } finally {
      // need to reset the abort controller
      this.abortController = new AbortController();
      console.log("Reset NFC abort controller");
    }
  }

  scan(): Promise<VisitTag> {
    return new Promise((resolve, reject) => {
      this.reader.onreading = (tagData: NDEFReadingEvent) => {
        const tag_sn = tagData.serialNumber;
        console.log("Tag detected", tag_sn);
        const visitManager = new VisitManager();
        for (const record of tagData.message.records) {
          try {
            visitManager.loadVisitsFromNDEFRecord(record);
            break;
          } catch (e) {
            console.log("Error while reading NFC tag", e);
            reject(e);
          }
        }
        resolve(new VisitTag(tag_sn, visitManager));
      };
      this.reader.onreadingerror = (e) => {
        console.log("Error while reading NFC tag", e);
        reject(e);
      };
      try {
        this.reader.scan({ signal: this.abortController.signal });
      } catch (e) {
        console.log("Error while reading NFC tag", e);
        // reject(e);
      }
    });
  }

  write(message: VisitManager): Promise<VisitTag> {
    return new Promise((resolve, reject) => {
      // cancel the current reading
      this.cancel();
      console.log("Reader canceled, install writing");
      // need to create a function or else the resolve/reject will be undefined
      function onRead(this: NFCReader, ndef: NDEFReadingEvent) {
        console.log("Tag detected, writing");
        const visit = new VisitTag(ndef.serialNumber, message);

        this.reader
          .write(message.toString(), {
            signal: this.abortController.signal,
          })
          .then(() => {
            console.log("Write done", visit);
            resolve(visit);
          })
          .catch((e) => {
            console.log("Error while writing NFC tag", e);
            // reject(e);
          });
      }

      this.reader.onreading = onRead.bind(this);
      console.log("Write Handler installer");
      // this.reader.onreadingerror = (e) => {
      //    console.log("Error while reading NFC tag", e);
      //    reject(e);
      // };
      console.log("Write Error Handler installer");
      this.reader.scan({ signal: this.abortController.signal });
      console.log("Write scan");
    });
  }
}

export class FakeReader implements NFCReaderInterface {
  private visits: VisitManager;
  private readonly tag: VisitTag;
  constructor() {
    console.log("Fake NFC Reader");
    this.visits = new VisitManager();
    // create a fake VE and DP
    const arrival = "08:00";
    const departure = "18:00";
    const today = new Date();
    // ve
    const ve = new VisitMessage(
      createDateFromTimeInput(today, arrival),
      createDateFromTimeInput(today, departure),
      InterventionType.Maintenance
    );
    // dp
    const dp = new VisitMessage(
      createDateFromTimeInput(today, arrival),
      createDateFromTimeInput(today, departure),
      InterventionType.Repair
    );
    this.visits.addVisit(ve);
    this.visits.addVisit(dp);
    this.tag = new VisitTag("fake", this.visits);
  }
  cancel(): void {
    return;
  }

  scan(): Promise<VisitTag> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.tag);
      }, 1000);
    });
  }

  write(message: VisitManager): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.visits = message;
        this.tag.visits = message;
        resolve(this.tag);
      }, 1000);
    });
  }
}

export enum NFCStatus {
  NFC_NOT_SUPPORTED = "NFC_NOT_SUPPORTED",
  NFC_SUPPORTED = "NFC_SUPPORTED",
  NFC_FAKE = "NFC_FAKE",
}

export function getNFCFunctionality(): NFCStatus {
  // check if nfc in the query string is not null
  const urlParams = new URLSearchParams(window.location.search);
  const nfc = urlParams.get("nfc");
  if (nfc !== null) {
    return NFCStatus.NFC_FAKE;
  }
  if ("NDEFReader" in window) {
    return NFCStatus.NFC_SUPPORTED;
  }
  return NFCStatus.NFC_NOT_SUPPORTED;
}

// function used to register a tag to a premise
export async function registerTagToPremise(premisesId: string, tagUid: string) {
  const tagUrl = `/tags`;
  console.log("Registering tag", tagUid, "to premise", premisesId);
  // first search by the tag uid if the tag is already registered
  const response = await _axios.get(tagUrl, {
    params: {
      uid: tagUid,
    },
  });
  // if 200, we continue
  if (response.status !== 200) {
    throw new Error("Error while registering tag");
  }

  let tagId: string | null = null;
  // if there is a result, we get the first one of the array
  if (response.data.length > 0) {
    tagId = response.data[0]["id"];
  } else {
    // create the tag
    const tagResponse = await _axios.post(tagUrl, {
      uid: tagUid,
    });
    // if 201 we continue
    if (tagResponse.status !== 201) {
      throw new Error("Error while registering tag");
    }
    // get the id and store it
    tagId = tagResponse.data["id"];
  }
  // then register the tag to the premise, we have the tag id from the response
  const registerUrl = `/tags/${tagId}/premises`;
  // 409 or 201 are ok

  const registerResponse = await _axios.post(
    registerUrl,
    {
      premises_id: premisesId,
    },
    { validateStatus: (status) => status === 201 || status === 409 }
  );
  console.log("Registering tag response", registerResponse);
  await store.dispatch("alert/success", "Tag successfully registered");
  return tagUid;
}

export async function getPremisesTag(premisesId: string) {
  const url = `/premises/${premisesId}/tag`;
  const response = await _axios.get(url);
  if (response.status !== 200) {
    throw new Error("Error while getting premises tags");
  }
  return response.data;
}
