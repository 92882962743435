/*
 * Location services
 */

import { backendConfig } from "@/_helpers";
import { _axios } from "@/plugins/axios";

export const locationService = {
  getAll,
};

function getAll() {
  // console.log("[locations] getting possible locations...");

  return _axios
    .get(`${backendConfig.inventoryLocationsEndpoint}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("[locations] get error:", error);
    });
}
