import { Module } from "vuex";
import { NFCReader, NFCReaderInterface } from "@/_services/nfc.service";
import { VisitManager, VisitTag } from "@/_helpers/visits";

export enum NfcState {
  IDLE = 1,
  INIT = 2,
  READING = 3,
  WRITING = 5,
  CHECKING = 5,
  ERROR = 6,
}

export interface NFCState {
  hasNFC: boolean;
  hasVisit: boolean;
  visitManager?: VisitManager;
  message?: VisitTag;
  NFCBackend?: NFCReaderInterface;
  state: NfcState;
  tagId?: string;
  tagIsRegistered: boolean;
  scanDate?: Date;
}

export const NFCModule: Module<NFCState, any> = {
  /*
   * Store the information about the NFC state
   * Store information like the tag content, is the NFC is active, etc.
   */

  namespaced: true,
  state: {
    hasNFC: false,
    message: undefined,
    NFCBackend: undefined,
    state: NfcState.IDLE,
    tagId: undefined,
    tagIsRegistered: false,
    hasVisit: false,
    visitManager: undefined,
    scanDate: undefined,
  },
  actions: {
    setHasNfc({ commit }, hasNfc: boolean) {
      commit("setHasNfc", hasNfc);
    },
    setNFCBackend({ commit }, NFCBackend: NFCReader) {
      commit("setNFCBackend", NFCBackend);
    },
    setScanDate({ commit }, scanDate: Date) {
      commit("setScanDate", scanDate);
    },
    setScanDateNow({ commit }) {
      commit("setScanDate", new Date());
    },
    clearScanDate({ commit }) {
      commit("setScanDate", undefined);
    },
    readNfc({ commit, state }): undefined | Promise<VisitTag> {
      if (!state.NFCBackend) {
        console.log("No NFC Backend");
        return;
      }
      commit("setNfcState", NfcState.READING);
      return new Promise((resolve, reject) => {
        state.NFCBackend?.scan().then((message: VisitTag) => {
          commit("setNfcState", NfcState.IDLE);
          commit("setNfcMessages", message);

          commit("setNfcTagId", message.serialNumber);
          resolve(message);
        });
      });
    },
    stopNfc({ commit, state }) {
      console.log("Vuex - Stopping NFC");
      commit("setNfcState", NfcState.IDLE);
      try {
        state.NFCBackend?.cancel();
      } catch (e) {
        console.log("Error while stopping NFC", e);
      }
    },
    writeNfc({ commit, state }, message: VisitManager): Promise<VisitTag> {
      commit("setNfcState", NfcState.WRITING);
      commit("setNfcMessages", message);
      return new Promise((resolve, reject) => {
        if (!state.NFCBackend) {
          console.log("No NFC Backend");
          reject("No NFC Backend");
        }
        state.NFCBackend?.write(message)
          .then((visitTag) => {
            commit("setNfcState", NfcState.IDLE);
            commit("setNfcMessages", visitTag.visits);
            commit("setNfcTagId", visitTag.serialNumber);
            resolve(visitTag);
          })
          .catch((e) => {
            console.log("Error while writing NFC", e);
            // commit("setNfcState", NfcState.ERROR);
            reject(e);
          });
      });
    },
    onNfcRead({ commit, state }, message: VisitTag) {
      if (state.state !== NfcState.READING) {
        return;
      }
      commit("setNfcMessages", message);
    },
    clearNfcMessages({ commit }) {
      commit("clearNfcMessages");
    },
  },
  mutations: {
    setHasNfc(state, hasNfc: boolean) {
      state.hasNFC = hasNfc;
    },
    setNfcState(state, nfcState: NfcState) {
      state.state = nfcState;
    },
    setNfcMessages(state, message: VisitTag) {
      state.message = message;
      state.hasVisit = true;
      state.visitManager = state.message.visits;
    },
    setNfcTagId(state, tagId: string) {
      // remove all the `:` from the tagId
      tagId = tagId.replace(/:/g, "");
      // all uppercase
      tagId = tagId.toUpperCase();
      state.tagId = tagId;
    },
    setNFCBackend(state, NFCBackend: NFCReader) {
      state.NFCBackend = NFCBackend;
    },
    clearNfcMessages(state) {
      state.message = undefined;
      state.tagId = undefined;
      state.hasVisit = false;
      state.visitManager = undefined;
    },
    setScanDate(state, scanDate: Date) {
      state.scanDate = scanDate;
    },
  },
};

export default NFCModule;
