import { set } from "date-fns";

export enum InterventionType {
  Maintenance = "VE",
  Repair = "DP",
}

export class VisitManager {
  // Only the latest's intervention type is kept
  visits: Map<InterventionType, VisitMessage> = new Map<
    InterventionType,
    VisitMessage
  >();
  // limit of the NTAG213
  readonly maxVisits = 6;

  public loadVisitsFromNDEFRecord(record: NDEFRecord) {
    /**
     * the record contain smoething like this "VisitMessage\nVisitMessage"
     *
     * We need to split by \n and then parse each message
     */
    if (record.recordType !== "text") {
      throw new Error("Invalid record type");
    }
    if (!record.data) {
      throw new Error("Invalid record data");
    }
    const data = new TextDecoder().decode(record.data);
    const messages = data.split("\n");
    for (const message of messages) {
      const visit = VisitMessage.fromString(message);
      this.addVisit(visit);
    }
  }

  addVisit(visit: VisitMessage) {
    // replace / set the visit if it's the same type
    this.visits.set(visit.type, visit);
  }

  getVisits() {
    return [...this.visits.values()];
  }

  toString() {
    // return the visits .toString joined by a \n
    return this.getVisits()
      .map((x) => x.toString())
      .join("\n");
  }
  clear() {
    this.visits.clear();
  }
}

/**
 * Represent the tag with a serial number and a visitManager attached to the tag
 */
export class VisitTag {
  serialNumber: string;
  visits: VisitManager;
  constructor(serialNumber: string, visits: VisitManager) {
    this.serialNumber = serialNumber;
    this.visits = visits;
  }
}

export class VisitMessage {
  start: Date;
  end: Date;
  type: InterventionType;
  constructor(start: Date, end: Date, type: InterventionType) {
    this.start = start;
    this.end = end;
    this.type = type;
  }

  static fromString(message: string): VisitMessage {
    const parts = message.split("|");
    if (parts.length !== 3) {
      throw new Error("Invalid message format");
    }
    const type = parts[0] as InterventionType;
    // we round to seconds
    const start = new Date(Date.parse(parts[1]));
    const end = new Date(Date.parse(parts[2]));
    return new VisitMessage(start, end, type);
  }

  // static fromNDEFRecord(record: NDEFRecord) {
  //     if (record.recordType !== 'unknown') {
  //         throw new Error('Invalid record type');
  //     }
  //     if (!record.data) {
  //         throw new Error('Invalid record data');
  //     }
  //     const data = new DataView(record.data.buffer);
  //     const start = data.getBigUint64(0);
  //     const end = data.getBigUint64(8);
  //     const type = data.getUint8(16);
  //     return new VisitMessage(Number(start), Number(end), type);
  // }

  toString() {
    return `${this.type}|${this.start.toISOString()}|${this.end.toISOString()}`;
  }
}

export function createDateFromTimeInput(date: Date, timeInput: string): Date {
  /**
   * Create a date from a date and a time input
   */
  const [hour, minute] = timeInput.split(":");
  return set(date, {
    hours: parseInt(hour, 10),
    minutes: parseInt(minute, 10),
    seconds: 0,
  });
}

export function unixTimestampSeconds(date: number = Date.now()): number {
  /**
   * Returns the unix timestamp in seconds
   */
  return Math.floor(date / 1000);
}
