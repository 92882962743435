import * as localStorageService from "@/_services";

/*
 * JWT authentication tokens management functions
 * -----
 * As a parameter it receives the token type, default is
 * access_token, else it should be refresh_token.
 */
export function processTokens(tokens, log = false) {
  if (tokens.access_token && tokens.refresh_token) {
    // store the jwt tokens in the local storage to keep user logged in between page refreshes
    localStorageService.writeToStorage("access_token", tokens.access_token);
    localStorageService.writeToStorage("refresh_token", tokens.refresh_token);

    if (log) {
      console.log("Parsed access token: ", jwtParse(tokens.access_token));
    }
    return {
      access: tokens.access_token,
      refresh: tokens.refresh_token,
    };
  } else if (tokens.access && tokens.refresh) {
    // store the jwt tokens in the local storage to keep user logged in between page refreshes
    localStorageService.writeToStorage("access_token", tokens.access);
    localStorageService.writeToStorage("refresh_token", tokens.refresh);

    if (log) {
      console.log("tech Parsed access token: ", jwtParse(tokens.access));
    }
    return {
      access: tokens.access,
      refresh: tokens.refresh,
    };
  }
  console.error("[tokens] Got bad tokens!", tokens);
  throw new Error("Bad Tokens");
}

/*
 * Parse a JWT refresh token
 * -------------------------
 * Returns:
 * {
 *   sub: 265534,
 *   token_type: "refresh",
 *   exp: 1581790573,
 *   iat: 1579198573,
 *   jti: "22a43b1b0b694ceaa425f7b26c856960"
 * }
 *
 * exp - iat is the number of seconds before token expiry.
 */
export function jwtParse(token) {
  const base64Url = token.split(".")[1] || null;
  if (!base64Url) {
    return null;
  }
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
