import Vue from "vue";

import "mutationobserver-shim";

import "./assets/custom-vars.scss";

import "./plugins/bootstrap-vue";
import "./plugins/axios";
import i18n from "./i18n";

import App from "./App.vue";

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
if (
  typeof process.env.VUE_APP_SENTRY !== "undefined" &&
  process.env.VUE_APP_SENTRY === "yes"
) {
  Sentry.init({
    dsn: "https://38cbdf97f4144e25b523f7556005d4e6@sentry.vizavy.fr/39",
    release: process.env.SENTRY_RELEASE,
    environment: process.env.SENTRY_ENVIRONMENT || "production",
    integrations: [
      new VueIntegration({ Vue: Vue, attachProps: true, logErrors: true }),
    ],
  });

  console.log("Sentry initialized");
}

import "./registerServiceWorker";
import { store } from "./_store";
import { router } from "./_helpers";

// Global importation of all the dashboard widgets components
import "./components/_dashboard";

// Font Awesome icons importation
import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// Import all icons from the module
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

// Import all icons from the module
import {
  faAngry,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faBomb,
  faCaretDown,
  faCaretUp,
  faCheck,
  faFileAlt,
  faMapMarked,
  faMapMarker,
  faMinus,
  faPaperclip,
  faPlus,
  faQuestion,
  faSearch,
  faShare,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faThumbsUp,
  faUserPlus,
  faUserSlash,
  faWrench,
  faLink,
  faBan,
  faEyeSlash,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

/* import specific icons */
import { faNfcSymbol } from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Add  all icons to our library
library.add(
  faAngry,
  faAlignLeft,
  faAlignRight,
  faCircleCheck as IconDefinition,
  faAlignCenter,
  faBomb,
  faCaretDown,
  faCaretUp,
  faCheck,
  faFileAlt,
  faMapMarked,
  faMapMarker,
  faMinus,
  faPaperclip,
  faPlus,
  faQuestion,
  faSearch,
  faShare,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faThumbsUp,
  faUserPlus,
  faUserSlash,
  faWrench,
  faNfcSymbol,
  faLink,
  faBan,
  faEyeSlash,
  faTrashCan
);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
// On peut maintenant utiliser <font-awesome-icon icon="coffee" /> pour afficher l'icône coffee

// Notifications utilisateur
import Notifications from "vue-notification";
Vue.use(Notifications);

Vue.config.productionTip = true;

import "./plugins/vee-validate";

import "./registerServiceWorker";

// set up a fake backend according to the current configuration
const configureFakeBackend = () => import("./_helpers");
if (process.env.VUE_APP_API_ROOT === "fake") {
  console.log(
    "[app] Configuring a fake backend. Note that it is not suitable for production :-)"
  );
  configureFakeBackend();
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
