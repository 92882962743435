import { backendConfig } from "@/_helpers/backend-config";

const state = {
  apiProfile: "tenant",
};

const actions = {
  setApiProfile: ({ dispatch, commit, getters }, apiProfile) => {
    commit("setApiProfile", apiProfile);
  },
};

const getters = {
  apiProfile: (state) => {
    return state.apiProfile;
  },
  refreshTokenUrl: (state, getters) => {
    let backendURL = backendConfig[state.apiProfile].refreshEndpoint.replace(
      "<base_url>",
      getters.baseApiURL
    );
    return (
      backendURL ??
      backendConfig.tenant.refreshEndpoint.replace(
        "<base_url>",
        getters.baseApiURL
      )
    );
  },
  baseApiURL: (state) => {
    // let backendURL = get(backendConfig, state.profile + ".apiUrl");
    let backendURL = backendConfig[state.apiProfile].apiUrl;
    console.log("backendURL", backendURL, backendConfig[state.apiProfile]);
    return backendURL ?? backendConfig.tenant.apiUrl;
  },
};

const mutations = {
  setApiProfile(state, apiProfile) {
    console.log("Changing user API profile to: ", apiProfile);
    // if there's a valid profile use it (TODO: implement better check)
    state.apiProfile = apiProfile in backendConfig ? apiProfile : "technician";
    console.log("Changed user API profile to: ", state.apiProfile);
  },
};

export const apiConfig = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
