import Vue from "vue";
import { required, image, email, confirmed } from "vee-validate/dist/rules";
import { extend, configure } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";

// Use Vue-i18n for validation messages locales
import i18n from "../i18n";
configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`);

    return i18n.t(`validation.${values._rule_}`, values);
  },
});

// Install required rule
extend("required", required);

// Install email rule
extend("email", email);

// Install image rule
extend("image", image);
extend("confirmed", confirmed);

// Define a phone or email rule
extend("phoneOrEmail", (value) => {
  // Test a valid phone number
  const phoneRegex =
    /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/g;
  if (phoneRegex.test(value)) {
    return true;
  }

  // Test a valid mail
  const mailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g;
  if (mailRegex.test(value)) {
    return true;
  }

  return i18n.t("fields.rule_mail_phone");
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
