import { premiseService } from "@/_services";
import { router } from "@/_helpers";

const states = {
  IDLE: "",
  LOADING: "loading",
  SUCCESS: "success",
  EMPTY: "empty",
};

const state = {
  status: states.IDLE,
  // filter: {},
  selected: null,
  lastFilter: {},
  items: [],
  premisesDetails: {},
  itemsCount: 0,
  // filtering
  page: 1,
  pageSize: 5,

  text: "",
  gpsSearchRadius: 100,
  allowedCompanies: null,
  itemsFreshness: 0,
  cancelRequest: null,
};

const actions = {
  getAll({ commit, dispatch, state, getters, rootGetters }, filter) {
    // if (shallowEqual(filter, state.lastFilter) && (state.equipmentsTypesFreshness + 300000 < Date.now())) {
    //   console.log("[freshness] - premises.")
    //   return state.items;
    // }
    // state.lastFilter = filter;
    // console.log("Filter is: ", perPage, currentPage)
    // console.log(state);
    if (getters.requestInProgress) {
      commit("cancelRequest");
    }
    if (state.status === states.SUCCESS) {
      return state.items;
    }
    const myFilter = { ...filter, ...getters.getFilter };
    if (
      rootGetters["geolocation/isGeoLocationEnabled"] &&
      rootGetters["geolocation/isLocked"]
    ) {
      const coordinates = rootGetters["geolocation/getCoordinates"];
      myFilter["gps"] = {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        distance: state.gpsSearchRadius,
      };
    }

    console.log("MyFilter: ", myFilter);
    let data = premiseService.getAll(myFilter, state.controller).then(
      (data) => {
        let itemsCount = 0;
        if ("count" in data) {
          itemsCount = data.count;
        }

        if ("results" in data) {
          data = data.results;
        }
        // console.log("[premises] got", data);

        // Prepare the  data to store
        data &&
          data.forEach((premise) => {
            // console.log("[premises] Premise :", premise);
            premise._selected = false;
            premise._showDetails = false;
            let needDetail = false;
            // fixme: business / agency jam !
            if (filter && "business" in filter && filter.business) {
              if (!premise.agency) {
                premise.agency = premise.business;
                premise.business = filter.business;
              }
            }
            if (
              premise.building === undefined ||
              typeof premise.building === "string"
            ) {
              premise.building = null;
              needDetail = true;
            }
            if (premise.tenants === undefined) {
              premise.tenants = [];
              needDetail = true;
            }
            if (needDetail) {
              dispatch("updatePremisesDetail", premise);
            }
          });
        commit("getAllSuccess", { items: data, total: itemsCount });
        // commit("setItemsCount", itemsCount)

        // Parse premises tenants
        data &&
          data.forEach((premise) => {
            premise.tenants &&
              premise.tenants.forEach((tenant) => {
                console.log("Tenants: ", tenant);
                dispatch("tenants/getOne", tenant, { root: true });
              });
          });
        dispatch("alert/success", router.app.$t("premises.ok_message"), {
          root: true,
        });
        return data;
      },
      (error) => {
        // console.log("[Premises.module] Error: ", error);
        commit("getAllFailure", error);
        if (error && error !== "Unauthorized") {
          dispatch("alert/error", error, { root: true });
        } else {
          dispatch("account/userDenied", "Premises", { root: true });
        }
        return [];
      }
    );
    return data.then((data) => data).catch((error) => []);
  },
  setPage({ commit, dispatch }, page) {
    // console.log("[prem store] Setting page", page);
    commit("setPage", page);
    dispatch("getAll");
  },
  setText({ commit, dispatch }, text) {
    // console.log("[prem store] Setting text", text);
    commit("setText", text);
    dispatch("getAll");
  },
  setAllowedBusiness({ commit, dispatch }, business) {
    // console.log("[prem store] Setting business", business);
    commit("setAllowedBusiness", business);
    dispatch("getAll");
  },
  setCoordinate({ commit, dispatch }, coordinates) {
    // console.log("[prem store] Setting coordinate", coordinates);
    commit("updateCoordinated", coordinates);
    dispatch("getAll");
  },
  selectOne({ dispatch, commit }, item) {
    commit("selectOne", item);
  },
  async updatePremisesDetail({ commit, state, dispatch }, premises) {
    let premDetail = null;
    // console.log("Updated premise detail: ", state.premisesDetails, premises.id, premises.id in state.premisesDetails);
    // Fetch from the cache or from internet
    if (!(premises.id in state.premisesDetails)) {
      premDetail = await premiseService
        .getPremisesByURL(premises.url)
        .then((premisesData) => {
          // console.log("Updating detail of premises");
          // store a ref to facilitate the update
          premisesData.ref = premises;
          commit("updatePremisesDetails", {
            data: premisesData,
            id: premises.id,
          });
          return premisesData;
          // return premisesData;
        });
    } else {
      premDetail = state.premisesDetails[premises.id];
    }

    if (premises.building === null) {
      dispatch("updatePremiseBuilding", premDetail);
    }
    if (premises.tenants.length !== premDetail.tenants.length) {
      dispatch("updatePremiseTenants", premDetail);
    }
    // add last inventory date
    if (
      premises.last_inventory === undefined &&
      premDetail.last_inventory !== undefined
    ) {
      premises.last_inventory = premDetail.last_inventory;
    }
    return state.premisesDetails[premises.id];
  },
  async updatePremiseBuilding({ commit, state, dispatch }, premisesDetail) {
    // Use the cache of the building service
    const building = await dispatch(
      "buildings/getBuildingDetailByURL",
      premisesDetail.building,
      { root: true }
    );
    // update the building in the premise
    commit("updatePremiseBuilding", {
      premisesId: premisesDetail.id,
      building: building,
    });
  },
  async updatePremiseTenants({ commit, state, dispatch }, premisesDetail) {
    for (const tenant of premisesDetail.tenants) {
      // fixme: use the tenant store
      const tenantData = await dispatch("tenants/getTenantByURL", tenant, {
        root: true,
      });
      commit("updateTenantPremises", {
        premisesId: premisesDetail.id,
        tenant: tenantData,
      });
    }
  },
  clearAll({ commit }) {
    commit("clearAll");
  },
};

const mutations = {
  updateStatus(state, msg) {
    state.status = msg;
  },
  addRequest(state, token) {
    // state.filter = filter;
    // state.items = [];
    console.log("Add request !", token);
    state.selected = null;
    state.cancelRequest = token;
    state.status = states.LOADING;
    state.selected = null;
  },
  cancelRequest(state) {
    if (state.cancelRequest && state.cancelRequest?.abort) {
      state.cancelRequest.abort();
    }
    state.cancelRequest = null;
    state.status = states.IDLE;
    state.selected = null;
  },
  getAllSuccess(state, { items, total }) {
    state.status = states.SUCCESS;
    state.cancelRequest = null;
    state.items = items;
    console.log("Total :", total);
    if (state.itemsCount !== total) {
      state.itemsCount = total;
    }
    state.itemsFreshness = Date.now();
    state.selected = null;
  },
  getAllFailure(state, error) {
    state.status = "error";
    state.cancelRequest = null;
    state.error = error;
  },
  selectOne(state, item) {
    if (!item) {
      state.selected = null;
    } else {
      const found = state.items.find((premise) => premise.name === item.name);
      if (found) {
        if (state.selected) {
          state.selected.chosen = false;
        }
        found.chosen = true;
        state.selected = found;
      } else {
        state.selected = null;
      }
    }
  },
  updatePremisesDetails(state, { data, id }) {
    state.premisesDetails[id] = data;
  },
  updatePremiseBuilding(state, { premisesId, building }) {
    // fixme: use premisesDetails index
    const found = state.items.find((premise) => premise.id === premisesId);
    if (found) {
      found.building = building;
    }
  },
  updateTenantPremises(state, { premisesId, tenant }) {
    // fixme: use premisesDetails index
    const found = state.items.find((premise) => premise.id === premisesId);
    if (found) {
      found.tenants.push(tenant);
    }
  },
  setPage(state, page) {
    state.status = states.LOADING;
    state.page = page;
  },
  setText(state, text) {
    state.status = states.LOADING;
    state.page = 1;
    state.text = text;
  },
  setAllowedBusiness(state, business) {
    state.status = states.LOADING;
    state.page = 1;
    state.allowedCompanies = business;
  },
  clearAll(state) {
    state.page = 0;
    state.selected = null;
    state.lastFilter = {};
    state.items = [];
    state.premisesDetails = {};
    state.itemsCount = 0;
    state.page = 1;
    state.text = "";
    state.status = states.IDLE;
  },
};

const getters = {
  requestInProgress(state) {
    return !!state.cancelRequest;
  },
  getByName: (state) => (name) => {
    return state.items.find((premise) => premise.name === name);
  },
  getTotal: (state) => {
    return state.itemsCount;
  },
  getPageSize: (state) => {
    return state.pageSize;
  },
  getPage: (state) => {
    return state.page;
  },
  getTextFilter: (state) => {
    return state.text;
  },
  getFilter: (state) => {
    // console.log("Filter is: ", state.filter);
    let filter = {
      text: state.text,
      page: state.page,
      limit: state.pageSize,
      business_id: state.allowedCompanies,
    };
    console.log("Generated filter: ", filter);
    return filter;
  },
  getSelected: (state) => () => {
    return state.selected;
  },
  getBuilding: (state) => (premisesID) => {
    const premData = state.premisesDetails[premisesID];
    if (premData !== undefined) {
      premData.ref.building;
    }
    return null;
  },
  // Premises categories and types
  // eslint-disable-next-line no-unused-vars
  getCategories: (state) => (none) => {
    return [
      "Logement",
      "Pavillon",
      "Local associatif",
      "Communs",
      "Local professionnel",
      "Commerce",
      "Local interne",
      "Foyer",
    ];
  },
  getCategory: (state, getters) => (category) => {
    return getters.getCategories()[category - 1];
  },
  // eslint-disable-next-line no-unused-vars
  getTypes: (state) => () => {
    return [
      "Studio",
      "Type 1",
      "Type 1 Bis",
      "Type 1 Ter",
      "Type 2",
      "Type 2 Bis",
      "Type 3",
      "Type 3 Bis",
      "Type 4",
      "Type 4 Bis",
      "Type 5",
      "Type 5 Bis",
      "Type 6",
      "Type 6 Bis",
      "Type 7",
      "Type 7 Bis",
      "Type 8",
      "Type 8 Bis",
      "Type 9",
      "Type 9 Bis",
      "Loge",
      "Parties communes",
      "Salle de réunion",
      "Bureaux",
      "Surface commerciale",
      "Atelier",
      "Garage",
      "Cave",
    ];
  },
  getType: (state, getters) => (type) => {
    return getters.getTypes()[type - 1];
  },
  getFreshness: (state, getters) => () => {
    return state.itemsFreshness;
  },
};

export const premises = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
