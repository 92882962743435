export const backendConfig = {
  // Default base Api
  apiUrl: process.env.VUE_APP_API_ROOT || "not-defined",

  // Login endpoint (whatever the user role...)
  loginEndpoint: process.env.VUE_APP_API_ROOT + "/token",
  tokenLoginEndpoint: process.env.VUE_APP_API_ROOT + "/token_login",
  profileEndpoint: "/me",

  accessToken: "access_token",
  refreshToken: "refresh_token",

  allowedRoles: ["tenant", "technician"],
  // For the tenant role:
  tenant: {
    apiUrl: process.env.VUE_APP_API_TENANT,
    refreshEndpoint: "<base_url>/token/refresh",
  },
  // For the technician role:
  technician: {
    apiUrl: process.env.VUE_APP_API_VIZAVY,
    refreshEndpoint: "<base_url>/user/jwt-token/refresh",
  },
  // guest
  guest: {
    apiUrl: process.env.VUE_APP_API_VIZAVY,
  },
  registerEndpoint: process.env.VUE_APP_API_ALLOW_REGISTER && "/user/register",
  recoverEndpoint:
    process.env.VUE_APP_API_ALLOW_LOST_PASSWORD && "/user/recover",

  alertEndpoint: "/alert",
  businessesEndpoint: "/businesses",
  documentsEndpoint: "/documents",
  equipmentsEndpoint: "/equipments",
  equipmentsFormsEndpoint: "/equipment/inventory/forms",
  InventoryCompanyClientsEndpoint: "/equipment/inventory/clients",
  InventoryFormsClientEndpoint: "/equipment/inventory/forms/<client_id>",
  equipmentsTypesEndpoint: "/equipments/type",
  equipmentEndpoint: "/equipment",
  equipmentInventoryEndpoint: "/equipment/<equipment_id>/inventory/",
  equipmentInventoryUpdateEndpoint: "/equipment/inventory/<inventory_id>",
  inventoryEndpoint: "premises/<premise_id>/inventory",
  inventoryLocationsEndpoint: "/inventory/locations",
  logsEndpoint: "/logs",
  premisesEndpoint: "/premises",
  tenantEndpoint: "/tenant/<tenant_id>",
  buildingsEndpoint: "/buildings/",
  buildingEndpoint: "/building/<building_id>",
};
