import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  // fixme: require.context is heavy and breaks the jest test execution!
  // const locales = require.context(
  //   "./locales",
  //   true,
  //   /[A-Za-z0-9-_,\s]+\.json$/i
  // );
  const myLocales = {
    en: require("./locales/en.json"),
    fr: require("./locales/fr.json"),
    // todo: append any new language here!
  };
  const messages = {};
  Object.keys(myLocales).forEach((locale) => {
    // Import the base locale messages
    messages[locale] = myLocales[locale];

    // Import the Vee validation locales in the 'validation' property
    const veeMessages = import(`vee-validate/dist/locale/${locale}.json`);
    messages[locale].validation = veeMessages.message;

    // // Import the Momentjs locales (except english US that is self included)
    // if (locale !== "en") {
    //   require("moment/locale/" + locale);
    // }
  });
  return messages;
}

export default new VueI18n({
  // Use the language of the client browser per default
  locale: navigator.language.split("-")[0],
  // locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  // Use this pattern to check production mode
  // silentFallbackWarn: process.env.NODE_ENV === "production",
  // Because we translate into Vue components it is very verbose, so silent the fallback log...
  silentFallbackWarn: true,
  dateTimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      datePicker: {
        weekday: "long",
        day: "numeric",
        month: "long",
      },
      datePickerShort: {
        weekday: "long",
        day: "numeric",
        month: "long",
      },
      datePickerHeader: {
        weekday: "short",
        day: "numeric",
        month: "short",
      },
      datePickerBody: {
        weekday: "long",
        day: "numeric",
        month: "long",
      },
      datePickerHour: {
        hour: "numeric",
      },
    },
    fr: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      datePicker: {
        weekday: "long",
        day: "numeric",
        month: "long",
      },
      datePickerShort: {
        weekday: "short",
        day: "numeric",
        month: "long",
      },
      datePickerHeader: {
        weekday: "short",
        day: "numeric",
        month: "short",
      },
      datePickerBody: {
        weekday: "long",
        day: "numeric",
        month: "long",
      },
      datePickerHour: {
        hour: "numeric",
      },
    },
  },
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "USD",
      },
    },
    fr: {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
  },
});
