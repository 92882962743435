/*
 * Tenants services
 */

import { backendConfig } from "@/_helpers";
import { _axios } from "@/plugins/axios";
import { AxiosResponse } from "axios";

export const tenantService = {
  getTenantByURL,
  getTenantByID,
};

function getTenantByURL(url: string) {
  // console.log("[Tenant] Getting");

  return _axios
    .get(
      url?.includes("http") &&
        !url.includes("https") &&
        !url?.includes("localhost")
        ? url?.replace("http", "https")
        : url
    )
    .then((response: AxiosResponse<any>) => response.data);
}

function getTenantByID(tenantId: string) {
  return getTenantByURL(
    `${backendConfig.tenantEndpoint}`.replace("<tenant_id>", tenantId)
  );
}
