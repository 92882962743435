// https://github.com/damienlabat/moment-ferie-fr

import { addDays, getYear, isEqual, isWeekend } from "date-fns";

export function getEaster(year: number) {
  if (year < 325) {
    throw new RangeError("Cannot calculate Easter dates before 325 AD.");
  }

  function mod(a: number, b: number) {
    return a % b;
  }

  function div(a: number, b: number) {
    const q = a / b;
    if (q < 0) {
      throw new Error("Unexpected negative q");
    }
    return Math.floor(q);
  }

  const y = year,
    skipMarchDays = 21,
    a = mod(y, 19),
    b = div(y, 100),
    c = mod(y, 100),
    d = div(b, 4),
    e = mod(b, 4),
    f = div(b + 8, 25),
    g = div(b - f + 1, 3),
    h = mod(19 * a + b - d - g + 15, 30),
    i = div(c, 4),
    k = mod(c, 4),
    l = mod(32 + 2 * e + 2 * i - h - k, 7),
    m = div(a + 11 * h + 22 * l, 451),
    t = h + l - 7 * m + skipMarchDays,
    n = div(t, 31) + 3,
    p = mod(t, 31);

  return new Date(year, n - 1, p + 1);
}

export function lundiDePaques(Y: number) {
  return addDays(getEaster(Y), 1);
}

export function ascension(Y: number) {
  return addDays(getEaster(Y), 39);
}

export function pentecote(Y: number) {
  return addDays(getEaster(Y), 50);
}

export function jourDeLAn(Y: number) {
  return new Date(Y, 1, 1);
}

export function feteDuTravail(Y: number) {
  return new Date(Y, 5, 1);
}

export function victoireDesAllies(Y: number) {
  return new Date(Y, 5, 8);
}

export function feteNationale(Y: number) {
  return new Date(Y, 7, 14);
}

export function assomption(Y: number) {
  return new Date(Y, 8, 15);
}

export function toussaint(Y: number) {
  return new Date(Y, 11, 1);
}

export function armistice(Y: number) {
  return new Date(Y, 11, 11);
}

export function noel(Y: number) {
  return new Date(Y, 12, 25);
}

const listeFerie = [
  jourDeLAn,
  feteDuTravail,
  victoireDesAllies,
  feteNationale,
  assomption,
  toussaint,
  armistice,
  noel,
  getEaster,
  lundiDePaques,
  ascension,
  pentecote,
];

export function isFerie(day: Date): boolean {
  const year = getYear(day);
  for (const dateFunc of listeFerie) {
    if (isEqual(dateFunc(year), day.getDate())) {
      return true;
    }
  }
  return false;
}

export function isWorkingDay(day: Date): boolean {
  return !isWeekend(day) && !isFerie(day);
}
