/*
 * Local storage services
 */

export function readFromStorage(key: string) {
  if (localStorage.getItem(key)) {
    try {
      return JSON.parse(<string>localStorage.getItem(key));
    } catch (e) {
      localStorage.removeItem(key);
      throw e;
    }
  }
}

export function writeToStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromStorage(key: string) {
  localStorage.removeItem(key);
}

// export const localStorageService = {
//   readFromStorage,
//   writeToStorage,
//   removeFromStorage,
// };
