/*
 * Inventory services
 */

import { backendConfig } from "@/_helpers";
import { _axios } from "@/plugins/axios";

export const inventoryService = {
  getInventory,
  createInventory,
  updateInventory,
  getCompanyForm,
};

export const InventoryType = {
  NEW: 1,
  CHECKED: 2,
  MISSING: 3,
  DELETED: 4,
  NOT_VISIBLE: 5,
};

export const InventoryState = {
  NOT_VALIDATED: 1,
  INVALIDATE: 2,
  VALIDATED: 3,
};

function getInventory(premiseId: string | number) {
  // console.log("[inventory] Filtering for premise", premiseId);

  // convert to string if needed
  premiseId = String(premiseId);
  const url = backendConfig.inventoryEndpoint.replace(
    "<premise_id>",
    premiseId
  );

  return _axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.error("[inventory] get error:", error);
    });
}

function createInventory(
  equipmentId: string | number,
  payload: Record<any, any>
) {
  equipmentId = String(equipmentId);
  const url = backendConfig.equipmentInventoryEndpoint.replace(
    "<equipment_id>",
    equipmentId
  );
  return _axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.error("[inventory] get error:", error);
    });
}


function updateInventory(
  inventory_id: string | number,
  payload: Record<any, any>
) {
  inventory_id = String(inventory_id);
  const url = backendConfig.equipmentInventoryUpdateEndpoint.replace(
    "<inventory_id>",
    inventory_id
  );
  return _axios
    .patch(url, payload)
    .then((response) => response.data)
    .catch((error) => {
      console.error("[inventory] get error:", error);
    });
}

function getCompanyForm(company_id: string | number) {
  // console.log("[user] inventory companies");
  company_id = String(company_id);
  return _axios.get(
    `${backendConfig.InventoryFormsClientEndpoint}`.replace(
      "<client_id>",
      company_id
    )
  );
}
