<template>
  <div id="app">
    <router-view></router-view>

    <notifications group="notifications" position="top left"></notifications>
    <b-modal ref="update-modal" @ok="refreshApp">
      {{ $t("new_version") }}
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { store } from "./_store";
import {
  getNFCFunctionality,
  FakeReader,
  NFCReader,
  NFCStatus,
} from "@/_services/nfc.service";
require("../public/a2hs/a2hs");

export default {
  name: "App",
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      notifications: (state) => state.alert.queue,
    }),
  },
  watch: {
    notifications: {
      handler: function (queue) {
        let queueTop = queue[queue.length - 1];

        this.$notify({ ...queueTop, group: "notifications" });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    const defaultLanguage = navigator.language.split("-")[0];

    console.log(`-----`);
    console.log(`Application configuration:`);
    console.log(`-> ${process.env.APP_NAME} - ${process.env.APP_VERSION}`);
    if (
      typeof process.env.BASE_URL !== "undefined" &&
      process.env.BASE_URL !== ""
    ) {
      console.log(`-> application Web location is ${process.env.BASE_URL}`);
    }
    console.log(`-> API root endpoint: ${process.env.VUE_APP_API_ROOT}`);
    if (
      typeof process.env.VUE_APP_API_X_USER_AGENT !== "undefined" &&
      process.env.VUE_APP_API_X_USER_AGENT !== ""
    ) {
      console.log(
        `-> x-user-agent: ${process.env.APP_NAME}/${process.env.APP_VERSION}`
      );
    } else {
      console.log(`-> no x-user-agent header`);
    }
    if (
      typeof process.env.VUE_APP_GPS_DISABLED !== "undefined" &&
      process.env.VUE_APP_GPS_DISABLED !== ""
    ) {
      console.log(`-> no GPS positioning`);
    } else {
      console.log(`-> using GPS positioning`);
    }
    console.log(
      `-> Register new users: ${process.env.VUE_APP_API_ALLOW_REGISTER}, recover lost passwords: ${process.env.VUE_APP_API_ALLOW_LOST_PASSWORD}`
    );
    console.log(
      `-> A2HS, id: '${process.env.VUE_APP_A2HS_APP_ID}', application name: '${process.env.VUE_APP_A2HS_APP_NAME}', logging: ${process.env.VUE_APP_A2HS_LOGGING}`
    );
    if (
      typeof process.env.VUE_APP_STORE_KEY !== "undefined" &&
      process.env.VUE_APP_STORE_KEY !== ""
    ) {
      console.log(
        `-> data store persisted in ${process.env.VUE_APP_STORE_KEY}`
      );
    } else {
      console.log(`-> data store is not persisted`);
    }
    if (
      typeof process.env.VUE_APP_STORE_LOGGING !== "undefined" &&
      process.env.VUE_APP_STORE_LOGGING !== ""
    ) {
      console.log(
        `-> data store logging: ${process.env.VUE_APP_STORE_LOGGING}`
      );
    }
    console.log(`-----`);

    // Get some configuration information from the API
    // todo: move this to an authentified zone ?
    this.getBusinesses();

    // test NFC presence and set the store
    const NFCFunctionality = getNFCFunctionality();
    if (NFCFunctionality === NFCStatus.NFC_FAKE) {
      store.dispatch("NFCModule/setHasNfc", true);
      store.dispatch("NFCModule/setNFCBackend", new FakeReader());
    } else if (NFCFunctionality === NFCStatus.NFC_SUPPORTED) {
      store.dispatch("NFCModule/setHasNfc", true);
      store.dispatch("NFCModule/setNFCBackend", new NFCReader());
    } else {
      store.dispatch("NFCModule/setHasNfc", false);
    }

    // Event handler for the locale changed with the locale changer component
    this.$root.$on("locale-signal", (code) => {
      if (code === "default") {
        code = defaultLanguage;
      }
      const localeCode = code;
      const localeName = this.$i18n.t("name");

      // Set the global language
      this.$i18n.locale = localeCode;

      // Force the view update
      this.$forceUpdate();

      // Set the current user locale
      this.setLocale({ code: localeCode, name: localeName });
    });

    // Event handler for the creation of a new intervention log
    this.$root.$on("new-log", () => {
      // Force the view update
      this.$forceUpdate();

      setTimeout(function () {
        store.dispatch("logs/getAll");
        store.dispatch("equipments/getAll");
      }, 500);
    });

    if (navigator.serviceWorker !== undefined) {
      // install an listener to trigger the update UI
      document.addEventListener("swUpdated", this.showRefreshUI, {
        once: true,
      });
      // prevent triggering multiple reload
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) {
          return;
        }
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  mounted() {
    window.addToHomescreen({
      appID: process.env.VUE_APP_A2HS_APP_ID || "vizavy.a2hs",
      appName: process.env.VUE_APP_APP_NAME || "My Vizavy",
      logging: process.env.VUE_APP_A2HS_LOGGING === "true",
      lifespan: 15,
      startDelay: 1,
      autostart: false,
      skipFirstVisit: false,
      minSessions: 1,
      displayPace: 0,
      customPrompt: {
        src: "img/icons/android-chrome-192x192.png",
        title: this.$t("installation.message"),
        cancelMsg: this.$t("installation.b_cancel"),
        installMsg: this.$t("installation.b_install"),
      },
    });
  },
  methods: {
    ...mapActions("account", ["setLocale"]),
    ...mapActions("businesses", { getBusinesses: "getAll" }),
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
      this.$refs["update-modal"].show();
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>

<style lang="scss">
// Import custom SASS variable overrides, or alternatively
// define your variable overrides here instead
@import "assets/custom-vars.scss";

//// Import Bootstrap and BootstrapVue source SCSS files
//@import "~bootstrap/scss/bootstrap.scss";
//@import "~bootstrap-vue/src/index.scss";

body,
html {
  min-height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50;*/
  margin-top: 60px;
  /* margin-bottom: 50px;*/
  min-height: 100%;
}

.card-title {
  text-align: left;
}
</style>

<i18n>
  {
    "en": {
      "new_version": "A new version is available. Do you want to update ?"
    },
    "fr": {
      "new_version": "Une nouvelle version de l'application est disponible, voulez vous mettre à jour ?"
    }
  }
</i18n>
