<template>
  <viz-base-tile :id="id" :data="data">
    <template #name>
      <cite>I am a Premises widget #{{ id }} : {{ data }}</cite>
    </template>

    <template #body>
      <b-card-text>
        <em v-if="premises.status === 'loading'">
          <font-awesome-icon icon="spinner" size="2x" spin></font-awesome-icon>
          &nbsp; {{ $t("actions.t_loading") }}
        </em>
        <div v-else-if="premises.status === 'error'" class="m-3 bg-danger">
          <h1>Could not get premises: {{ premises.error }}!</h1>
        </div>
        <div v-else-if="premises.status === 'success' && premises.items">
          <ValidationObserver ref="form-observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(requestSearch)">
              <b-row v-if="isFilterableText">
                <b-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="f_premises_search"
                    mode="lazy"
                  >
                    <b-form-group
                      id="premises-filter"
                      :label="$t('fields.f_premises_search')"
                      label-for="premises-filter-input"
                      label-size="sm"
                      label-sr-only
                    >
                      <b-input-group class="mt-1">
                        <b-input-group-prepend v-if="hasGeoLocation">
                          <b-button
                            id="gpsButton"
                            :variant="locationStateButton"
                            size="sm"
                            @click="toggleGPS"
                            ><font-awesome-icon
                              icon="map-marked"
                              :class="
                                isGeoLocationEnabled && !isLocked
                                  ? 'b-icon bi b-icon-animation-fade'
                                  : ''
                              "
                            />
                          </b-button>
                        </b-input-group-prepend>
                        <b-form-select
                          v-if="isFilterableBusiness && myBusinesses.length > 1"
                          id="premises-select-business"
                          v-model="chosenBusiness"
                          size="sm"
                          :options="businessesList()"
                          @change="requestSearch"
                        >
                          <template #first>
                            <b-form-select-option value="null" disabled>{{
                              $t(`businesses.choose`)
                            }}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <b-form-input
                          id="premises-filter-input"
                          v-model="textFilter"
                          trim
                          autofocus
                          type="search"
                          size="sm"
                          :placeholder="$t('fields.f_premises_search')"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button type="submit" variant="primary" size="sm"
                            ><font-awesome-icon icon="search"
                          /></b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <b-form-invalid-feedback
                        id="premises-filter-input-feedback"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
          <div v-if="chosenPremise">
            <b-row>
              <b-col v-b-toggle.premises-card sm="6" class="text-sm-left"
                >{{ $t("premises.inventory") }}
                <strong v-if="chosenPremise.provider_name">{{
                  chosenPremise.provider_name
                }}</strong>
                <strong v-else>{{ chosenPremise.name }}</strong
                ><span v-if="chosenPremise.building">
                  - {{ chosenPremise.building.street1 }}</span
                >
              </b-col>
              <b-col>
                <b-button
                  size="sm"
                  variant="warning"
                  :title="$t('premises.stop_inventory_premise')"
                  class="mt-2"
                  @click="terminateInventory(chosenPremise)"
                >
                  <span class="sr-only">{{
                    $t("premises.stop_inventory_premise")
                  }}</span>
                  <font-awesome-icon
                    aria-hidden="true"
                    icon="minus"
                    size="1x"
                  />&nbsp;{{ $t("premises.stop_inventory_premise") }}
                </b-button>
                &nbsp;
                <b-button
                  size="sm"
                  variant="danger"
                  :title="$t('premises.cancel_inventory_premise')"
                  class="mt-2"
                  @click="confirmCancelInventory(chosenPremise)"
                >
                  <span class="sr-only"
                    >{ $t('premises.cancel_inventory_premise') }</span
                  >
                  <font-awesome-icon
                    aria-hidden="true"
                    icon="minus"
                    size="1x"
                  />&nbsp;{{ $t("premises.cancel_inventory_premise") }}
                </b-button>
              </b-col>
            </b-row>

            <b-collapse id="premises-card">
              <b-card>
                <b-list-group flush>
                  <b-list-group-item
                    v-for="(value, key) in chosenPremise"
                    v-if="isDisplayed(key) && value"
                    :key="key"
                  >
                    <b-row>
                      <b-col sm="4" class="text-sm-right"
                        ><strong>{{
                          $t(`premises.fields.${key}`)
                        }}</strong></b-col
                      >
                      <b-col v-if="key === 'category'" class="text-sm-left">{{
                        getPremiseCategory(value)
                      }}</b-col>
                      <b-col v-else-if="key === 'type'" class="text-sm-left">{{
                        getPremiseType(value)
                      }}</b-col>
                      <b-col v-else-if="key === 'tenants'" class="text-sm-left">
                        <b-list-group flush>
                          <b-list-group-item
                            v-for="(tenant, key2) in value"
                            :key="key2"
                          >
                            {{
                              getTenantByName(
                                tenant.first_name,
                                tenant.last_name
                              )
                            }}
                          </b-list-group-item>
                        </b-list-group>
                      </b-col>
                      <b-col v-else class="text-sm-left">{{ value }}</b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-collapse>
          </div>
          <div v-else>
            <div v-if="getTotal > 0">
              <!--p>{{getPage }} {{ getTotal}}</-->
              <!--              <h2>{{ premises.itemsCount }}</h2>-->
              <b-table
                :id="`table-premises-${id}`"
                :sticky-header="data.tableHeaderCss"
                :caption="data.tableCaption"
                caption-top
                primary-key="name"
                :current-page="getPage"
                :items="premises.items"
                :fields="fields"
                selectable
                select-mode="single"
                striped
                hover
                :filter="filter"
                :filter-included-fields="filterOn"
                :responsive="data.tableResponsive"
                style="cursor: pointer"
                :tbody-tr-class="rowClass"
                @row-clicked="rowClicked"
                @row-selected="rowSelected"
              >
                <!-- selected state -->
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span class="sr-only">Selected</span>
                    <font-awesome-icon
                      aria-hidden="true"
                      icon="check"
                      size="1x"
                    ></font-awesome-icon>
                  </template>
                  <template v-else>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>

                <!-- availability state -->
                <template #cell(availability)="data">
                  <span :title="$t('premises.empty_premise')">
                    <StatusBox
                      :state="!data.value"
                      ok-icon=""
                      ko-icon="user-slash"
                    ></StatusBox>
                  </span>
                </template>

                <!-- Premise references -->
                <template #cell(name)="data">
                  <b-row class="my-1">
                    <b-col xs="12" sm="12">
                      <strong class="text-primary">
                        <span v-if="data.item.provider_name"
                          >{{ data.item.provider_name }}
                          {{ getBuilding(data.item.id) }}</span
                        ><span v-else>{{ data.item.name }}</span>
                        <span
                          v-if="
                            data.item.tenants && data.item.tenants.length > 0
                          "
                          >&nbsp;-&nbsp;{{
                            data.item.tenants[0].last_name
                          }}</span
                        >
                      </strong>
                      <br />
                      <span>
                        <em v-if="data.item.building"
                          >{{ data.item.building.street1 }} -
                          {{ data.item.building.city }}</em
                        >
                      </span>
                    </b-col>
                    <!--                  <b-col xs="12" sm="12">-->
                    <!--                    <strong class="text-primary">{{-->
                    <!--                      getPremiseCategory(data.item.category)-->
                    <!--                    }}</strong>-->
                    <!--                    <span>-->
                    <!--                      &nbsp;<em>({{ getPremiseType(data.item.type) }})</em>-->
                    <!--                    </span>-->
                    <!--                  </b-col>-->
                  </b-row>
                </template>

                <!-- Choose the premise if selected -->
                <template #cell(choose_premise)="row">
                  <div class="action-wrapper">
                    <!-- CR Button -->
                    <!-- <b-button
                      v-if="row.item._selected && !chosenPremise"
                      size="sm"
                      variant="primary"
                      :title="$t('premises.report_intervention')"
                      @click="goToDemo()"
                    >
                      <span class="sr-only"
                        >{ $t('nfc.report_intervention') }</span
                      >
                      <font-awesome-icon
                        aria-hidden="true"
                        icon="fa-plus"
                        size="1x"
                      />&nbsp;{{ $t("premises.report_intervention") }}
                    </b-button> -->

                    <b-button
                      v-if="row.item._selected && !chosenPremise"
                      size="sm"
                      variant="primary"
                      :title="$t('premises.start_inventory_premise')"
                      @click="choosePremise(row.item)"
                    >
                      <span class="sr-only"
                        >{ $t('premises.start_inventory_premise') }</span
                      >
                      <font-awesome-icon
                        aria-hidden="true"
                        icon="fa-plus"
                        size="1x"
                      />&nbsp;{{ $t("premises.start_inventory_premise") }}
                    </b-button>

                    <b-button
                      v-if="
                        row.item._selected &&
                        !chosenPremise &&
                        hasNFC &&
                        !scanDate
                      "
                      size="sm"
                      variant="primary"
                      class="m-2"
                      :title="$t('premises.start_inventory_premise')"
                      @click="setScanDate()"
                    >
                      <font-awesome-icon icon="fa-brands fa-nfc-symbol" />
                      {{ $t("nfc.start_intervention") }}
                    </b-button>

                    <b-button
                      v-if="
                        row.item._selected &&
                        !chosenPremise &&
                        hasNFC &&
                        scanDate
                      "
                      size="sm"
                      variant="primary"
                      class="m-2"
                      :title="$t('premises.start_inventory_premise')"
                      @click="
                        NFCPremises = row.item;
                        $refs.nfc.show();
                      "
                    >
                      <font-awesome-icon icon="fa-brands fa-nfc-symbol" />
                      {{ $t("nfc.end_intervention") }}
                    </b-button>
                  </div>
                </template>

                <!-- Detailed information -->
                <template #row-details="row">
                  <b-card>
                    <b-list-group flush>
                      <b-list-group-item
                        v-for="(value, key) in row.item"
                        v-if="isDisplayed(key) && value"
                        :key="key"
                      >
                        <b-row>
                          <b-col sm="4" class="text-sm-right"
                            ><strong>{{
                              $t(`premises.fields.${key}`)
                            }}</strong></b-col
                          >
                          <b-col
                            v-if="key === 'category'"
                            class="text-sm-left"
                            >{{ getPremiseCategory(value) }}</b-col
                          >
                          <b-col
                            v-else-if="key === 'type'"
                            class="text-sm-left"
                            >{{ getPremiseType(value) }}</b-col
                          >
                          <b-col
                            v-else-if="key === 'last_inventory'"
                            class="text-sm-left"
                            >{{ value }}</b-col
                          >
                          <b-col
                            v-else-if="key === 'tenants'"
                            class="text-sm-left"
                          >
                            <b-list-group flush>
                              <b-list-group-item
                                v-for="tenant in value"
                                :key="tenant.id"
                              >
                                {{ tenant.first_name }} {{ tenant.last_name }}
                                <span v-if="tenant.phones">
                                  <br />Tél.: {{ tenant.phones }}
                                </span>
                                <span v-if="tenant.mobiles">
                                  <br />Mob.: {{ tenant.mobiles }}
                                </span>
                              </b-list-group-item>
                            </b-list-group>
                          </b-col>
                          <b-col v-else class="text-sm-left">{{ value }}</b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </template>
              </b-table>

              <b-pagination
                v-model="getPage"
                :total-rows="getTotal"
                :per-page="getPageSize"
                hide-goto-end-buttons
                size="sm"
                :aria-controls="`table-premises-${id}`"
                align="left"
                @change="setPage"
              ></b-pagination>
            </div>
            <div v-else-if="!textFilter && !isGeoLocationEnabled">
              {{ $t("premises.do_search") }}
              <div v-if="hasNFC">
                <b-button
                  variant="primary"
                  class="mt-2"
                  @click="$refs.nfcScanner.show()"
                >
                  <font-awesome-icon icon="fa-brands fa-nfc-symbol" />
                  {{ $t("nfc.search_by_tag") }}
                </b-button>
              </div>
            </div>
            <div v-else class="text-warning">
              {{ $t("premises.no_items") }}
            </div>
          </div>
        </div>

        <div v-else class="text-warning">No known premises!</div>
      </b-card-text>
      <b-modal
        ref="premise-confirm-modal"
        :title="confirmTitle"
        :visible="confirmShow"
        :ok-title="$t('yes')"
        :cancel-title="$t('no')"
        @ok="cancelInventory(chosenPremise)"
      >
        <p>{{ confirmText }}</p>
      </b-modal>
      <b-modal
        id="modal-register-nfc"
        ref="nfc"
        :title="$t('nfc.register_tag')"
        ok-only
        ok-variant="secondary"
        ok-title="Annuler"
        @ok="closeRegistration"
        @close="closeRegistration"
      >
        <NFCRegister
          :premises="NFCPremises"
          @tagWritten="tagWritten"
        ></NFCRegister>
      </b-modal>
      <b-modal
        id="nfc-scanner"
        ref="nfcScanner"
        :title="$t('nfc.scan_tag')"
        :hide-footer="true"
        :ok-disabled="true"
        @close="stopScan"
        @cancel="stopScan"
      >
        <NFCScanner @tagRead="tagRead"></NFCScanner>
      </b-modal>
    </template>
  </viz-base-tile>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import NFCScanner from "@/components/_dashboard/NFCScanner.vue";
import { VisitTag } from "@/_helpers/visits";

export default {
  name: "VizPremises",
  components: {
    NFCScanner,
    VizBaseTile: () => import("./BaseTile"),
    StatusBox: () => import("../StatusBox"),
    NFCRegister: () => import("../_dashboard/NFCRegister"),
  },
  props: {
    id: {
      type: String,
      default: "1",
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    // A nonsense for this component ;)
    // premised: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      // The list of the displayed field in the details pane
      displayedFields: [
        "name",
        "business",
        // "agency",
        // "building",
        "category",
        "type",
        "floor",
        "door",
        "tenants",
        // "availability",
        "address",
        "last_inventory",
      ],
      // The list of most useful fields
      fields: [
        {
          key: "availability",
          label: this.$t("premises.fields.status"),
        },
        {
          key: "name",
          label: this.$t("premises.fields.description"),
          sortable: true,
        },
        {
          key: "choose_premise",
        },
      ],
      // Filtering in the table
      filter: null,
      filterOn: ["name"],
      // The premises filtering
      filterText: true,
      filterBusiness: true,
      textFilter: "",
      chosenBusiness: null,
      // Geolocation features
      defaultDistance: 5000,
      triggerLocation: false,

      // modal
      confirmShow: false,
      confirmTitle: "",
      confirmText: "",

      // NFC Registration
      NFCPremises: null,
    };
  },
  computed: {
    ...mapGetters("inventory", {
      getEquipments: "getEquipments",
    }),
    ...mapGetters("equipments", {
      getEquipmentForm: "getEquipmentForm",
    }),
    ...mapGetters("tenants", {
      getTenantByName: "getNameAndDetails",
    }),
    ...mapGetters("businesses", {
      businessesList: "getSelectionList",
    }),
    ...mapGetters("premises", {
      getPremiseCategory: "getCategory",
      getPremiseType: "getType",
      getBuilding: "getBuilding",
      getPageSize: "getPageSize",
      getPage: "getPage",
      getTotal: "getTotal",
      getTextFilter: "getTextFilter",
    }),

    ...mapGetters("geolocation", {
      hasGeoLocation: "isGeoLocationSupported",
      isGeoLocationEnabled: "isGeoLocationEnabled",
      isLocked: "isLocked",
      // filterGps: "getGPSState",
    }),
    ...mapState("account", {
      allowedCompanies: "allowed_companies",
    }),
    ...mapState("NFCModule", {
      hasNFC: "hasNFC",
      scanDate: "scanDate",
    }),
    ...mapState({
      premises: (state) => {
        return state.premises;
      },
      chosenPremise: (state) => state.premises.selected,
    }),
    // rows() {
    //   return this.premisesTotal;
    // },
    isFilterableText() {
      // Priority for the layout defined value if it exists
      return "undefined" !== typeof this.data.filterText
        ? this.data.filterText
        : this.filterText;
    },
    isFilterableBusiness() {
      // Priority for the layout defined value if it exists
      return "undefined" !== typeof this.data.filterBusiness
        ? this.data.filterBusiness
        : this.filterBusiness;
    },
    isFilterableGps() {
      if (!this.isGeoLocationEnabled) {
        return this.isGeoLocationEnabled;
      }
      // Priority for the layout defined value if it exists
      return "undefined" !== typeof this.data.filterGps
        ? this.data.filterGps
        : this.filterGps;
    },
    myBusinesses() {
      return this.businessesList;
    },
    locationStateButton() {
      return this.isGeoLocationEnabled ? "primary" : "outline-primary primary";
    },
  },
  watch: {
    isLocked: function (locked) {
      // console.log("Locked change ? ", locked, oldState);
      if (locked && !this.triggerLocation) {
        this.triggerLocation = true;
        this.requestSearch();
      }
    },
    isGeoLocationEnabled: function (isActive, oldState) {
      this.$emit("updateTitle", "Test Update");
      if (isActive) {
        // clicked on the location button ? Start the count-down of 30s and reset
        this.triggerLocation = false;
        setTimeout(this.triggerDeadline, 30 * 1000);
      } else {
        // reset state + search to prevent a click.
        this.triggerLocation = false;
        this.requestSearch();
      }
    },
    // premisesTotal: function (newTotal, oldTotal) {
    //   console.log("Total change ? ", newTotal, oldTotal);
    // },
    premises: function (old, news) {
      console.log("Premises change ? ", old, news);
    },
  },
  created() {
    console.log(
      `[premises] Filtering status: text (${this.isFilterableText}), gps (${this.isFilterableGps}), business (${this.isFilterableBusiness})`
    );

    console.log("Loading inventories");
    this.$store.dispatch("account/loadCompaniesInventory");

    // Get all the available premises
    // this.requestSearch();
    if (this.isGeoLocationEnabled) {
      // restore location state
      this.enableLocation();
    }
    // console.log("Text filter is: ", this.textFilter, this.getTextFilter)
    this.textFilter = this.getTextFilter;
    this.setSearchText(this.textFilter);

    if (this.premises && this.premises.length > 0) {
      // No more selection nor details
      this.premises.forEach((item) => {
        item._selected = false;
        item._showDetails = false;
      });
    }
  },
  methods: {
    ...mapActions("inventory", {
      flushInventory: "flushInventory",
      clearInventory: "clearAll",
    }),
    ...mapActions("premises", {
      selectOne: "selectOne",
      // getFreshness: "getFreshness",
      setPage: "setPage",
      setCoordinates: "setCoordinate",
      //setGPS: "setGPS",
      setSearchText: "setText",
    }),
    ...mapActions("tenants", {
      addTenant: "addTenant",
    }),
    ...mapActions("geolocation", {
      toggleGPS: "toggleGPS",
      enableLocation: "enableLocation",
      disableLocation: "disableLocation",
    }),
    ...mapActions("NFCModule", {
      setScanDate: "setScanDateNow",
    }),
    rowClass(item) {
      if (!item) return;
      if (this.chosenPremise && this.chosenPremise.name === item.name)
        return "table-success";
    },
    triggerDeadline() {
      // console.log("Deadline called", this.isLocked);
      if (!this.isLocked) {
        this.disableLocation();
        this.$store.dispatch("alert/error", "Localisation impossible", {
          root: true,
        });
      }
    },
    // *** search management
    getFiltersParams() {
      const params = {};

      if (this.isFilterableText) {
        params["text"] = this.textFilter;
      }
      if (this.isFilterableBusiness) {
        // Force use the 1st business if only one exists
        if (
          !this.chosenBusiness &&
          this.myBusinesses &&
          this.myBusinesses.length === 1
        ) {
          this.chosenBusiness = this.myBusinesses[0].value;
        }
        params["business"] = this.chosenBusiness;
      }

      return params;
    },
    requestSearch() {
      if (this.chosenPremise) {
        this.cancelInventory(this.chosenPremise);
      }
      console.log("Text filter is: ", this.textFilter);
      // const params = this.getFiltersParams();
      // console.log("[premises] Submitting search request...", params);
      this.setSearchText(this.textFilter);
    },
    resetForm() {
      if (!this.$refs.formObserver) {
        return;
      }
      requestAnimationFrame(() => {
        this.$refs.formObserver.reset();
      });
    },
    // *** end search management
    isDisplayed(key) {
      return this.displayedFields.indexOf(key) >= 0;
    },
    // Premises list part
    rowSelected(items) {
      this.premises.items.forEach((item) => {
        item._selected = false;
        if (item._showDetails) {
          item._showDetails = false;
        }
      });

      if (items.length !== 0) {
        const premises = items[0];
        premises._selected = !premises._selected;
        premises._showDetails = true;
      }
    },
    rowClicked(item) {
      item._showDetails =
        typeof item._showDetails === "undefined" ? true : !item._showDetails;
    },
    // End premise list
    // *** inventory management (close / open / cancer ***
    confirmCancelInventory() {
      this.confirmText = this.$t("premises.confirm_cancel.content");
      this.confirmTitle = this.$t("premises.confirm_cancel.title");
      this.$refs["premise-confirm-modal"].show();
    },
    cancelInventory(item) {
      // triggered after a confirmation of cancelling the inventory
      // console.log("[inventory] canceling...");

      this.$refs["premise-confirm-modal"].hide();

      // close the detail
      item._showDetails = false;
      // Unselect the selected premise
      this.choosePremise(null);
      this.clearInventory();
    },
    terminateInventory(item) {
      // console.log("[inventory] terminating...");

      this.$refs["premise-confirm-modal"].hide();
      // Inventory validation
      let isValid = true;
      // Check if all displayed equipment has been treated
      this.getEquipments
        .sort((a, b) => {
          if (a.type > b.type) {
            return -1;
          }
          if (a.type < b.type) {
            return 1;
          }
          return 0;
        })
        .forEach((eqp) => {
          if (!eqp.features) {
            eqp.features = this.getEquipmentForm(eqp.type_id);
          }

          if (!eqp.inventoried) {
            console.log("equipment not inventoried");
            this.$store.dispatch(
              "alert/error",
              `${this.$t("inventory.please_check_equipment")}: ${eqp.type}`,
              {
                root: true,
              }
            );
            isValid = false;
            return;
          }

          if (eqp?.location?.length === 0 && eqp?.inventory_type === 2 && !eqp?.id) {
            this.$store.dispatch(
              "alert/error",
              `${this.$t("inventory.please_check_location")} : ${eqp.type}`,
              {
                root: true,
              }
            );
            isValid = false;
            return;
          }

          // Check present ( type === 2 ) equipments forms
          if (eqp.inventory_type === 2) {
            if (!eqp?.features?.fields || !eqp.metadata) {
              isValid = false;
              this.$store.dispatch(
                "alert/error",
                `${this.$t("inventory.please_check_equipment_form")}: ${
                  eqp.type
                }`,
                {
                  root: true,
                }
              );
              return;
            }
            eqp.features.fields
              .filter((item) => item.required)
              .forEach((item) => {
                if (
                  !eqp?.metadata[item.name] ||
                  !(eqp?.metadata[item.name].length > 0)
                ) {
                  console.log(
                    "Error Field : ",
                    item.name,
                    eqp?.metadata[item.name]
                  );
                  isValid = false;
                  this.$store.dispatch(
                    "alert/error",
                    `Veuillez remplir le formulaire de l'équipement: ${eqp.type}`,
                    {
                      root: true,
                    }
                  );
                  return;
                }
              });
          }
        });

      if (!isValid) {
        console.log("the inventory is not valid");
        return;
      } else {
        console.log("the inventory is valid");
      }
      // close the detail
      item._showDetails = false;
      // Unselect the selected premise
      this.choosePremise(null);
      // Flush the current inventory
      this.flushInventory();
    },
    // *** /inventory management **
    choosePremise(item) {
      // console.log("[inventory] starting...");

      // Enable / disable table filtering
      this.filter = item ? item.name : null;

      // Start a premise inventory
      this.selectOne(item);
    },
    goToDemo() {
      this.$router.push("/demo");
    },
    tagRead(tag) {
      this.$refs.nfcScanner.hide();

      // Check if visitTag
      if (tag instanceof VisitTag) {
        // console.log("[inventory] tag read: ", tag);
        this.textFilter = `#${tag.serialNumber}`;
        this.setSearchText(this.textFilter);
        return;
      }
    },
    tagWritten(tag) {
      // console.log("[inventory] tag written: ", tag);
      this.$store.dispatch("alert/success", "Tag écrit", { root: true });
      this.$store.dispatch("NFCModule/clearScanDate");
      this.$refs.nfc.hide();
    },
    closeRegistration() {
      this.$store.dispatch("NFCModule/stopNfc");
    },
    stopScan() {
      // console.log("[inventory] stop scan");

      this.$store.dispatch("NFCModule/stopNfc");
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Known premises"
  },
  "fr": {
    "title": "Logements connus"
  }
}
</i18n>

<style scoped>
.action-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
}
</style>
