/*
 * Users login, logout and other services
 */

import { AxiosResponse } from "axios";
import { backendConfig } from "@/_helpers";
import { store } from "@/_store";
import { _axios } from "@/plugins/axios";
import * as localStorageService from "@/_services/local-storage.service";
import removeFromStorage = localStorageService.removeFromStorage;

const fakeProfile = {
  role: "technician",

  firstName: "John",
  lastName: "Doe",
  changePassword: false,

  layout: {
    id: "technician",
    type: "role",
    rows: [
      {
        id: "row-1",
        columns: [
          {
            id: "premises",
            widget: "Premises",
            data: {
              title: "Mes logements",
              perPage: 10,
              tableHeaderCss: "440px",
              tableResponsive: true,
              premisesFilter: "***",
              filterText: true,
              filterCategory: false,
              filterType: false,
            },
          },
        ],
      },
      {
        id: "row-2",
        columns: [
          {
            id: "inventory",
            widget: "Inventory",
            premised: true,
            data: {
              title: "Inventaire",
              tableHeaderCss: "440px",
              tableResponsive: "sm",
            },
          },
        ],
      },
    ],
  },
};

export function login(username: string, password: string) {
  const params = new FormData();
  params.append("username", username);
  params.append("password", password);
  // const params = {
  //   username: username,
  //   password: password,
  // }
  // params.append("username", username);
  // params.append("password", password);

  // todo: change this !
  return _axios
    .post(`${backendConfig.loginEndpoint}`, params)
    .then((response: any) => response.data)
    .catch((error: any) => {
      const responseDetail = error.response ? error.response.data : error;
      store.dispatch(
        "account/logout",
        typeof responseDetail.detail !== "undefined"
          ? responseDetail.detail
          : responseDetail
      );
      return Promise.reject("users.ko_login");
    });
}

export function authWithToken(tokenString: string) {
  /*Use a token to get around the need for a username/password. 
  The user is identified by the backend before receiving the token

  Note that refresh should NOT be available when using this! */
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenString}`,
  };
  return _axios
    .post(`${backendConfig.tokenLoginEndpoint}`, {}, { headers: headers })
    .then((response: any) => response.data)
    .catch((error: any) => {
      const responseDetail = error.response ? error.response.data : error;
      store.dispatch(
        "account/logout",
        typeof responseDetail.detail !== "undefined"
          ? responseDetail.detail
          : responseDetail
      );
      return Promise.reject(responseDetail.detail ?? "user.ko_auth");
    });
}

export function changePassword(old_password: string, new_password: string) {
  return _axios
    .post("/user/change", {
      old_password: old_password,
      new_password: new_password,
    })
    .then(
      (response: any) => {
        if (response.data.success !== true) {
          return Promise.reject({
            incorrect_password: false,
            message: response.data.message,
            suggestions: response.data.suggestions,
          });
        }
        return Promise.resolve(true);
      },
      () => {
        return Promise.reject({
          incorrect_password: true,
          message: "Incorrect password",
        });
      }
    );
}

export function logout() {
  // remove user from local storage to log user out
  removeFromStorage("access_token");
  removeFromStorage("refresh_token");
}

export function register(user: any) {
  return _axios.post(`${backendConfig.registerEndpoint}`, user);
}

export function recover(username: string) {
  return _axios.post(`${backendConfig.recoverEndpoint}`, {
    email: username,
  });
}

export function getAll() {
  return _axios.get(`/users`);
}

export function getById(id: number) {
  return _axios.get(`/users/${id}`);
}

export function getUserProfile(defaultRole = "tenant") {
  return (
    _axios
      .get(`${backendConfig.profileEndpoint}`)
      // .then(handleResponse)
      .then((response: any) => {
        const profile = response.data; //.profile
        // console.log("[user] profile response:", profile);

        // Default role is a tenant role
        if (!profile.role) {
          // todo: force technician role if the user is not a tenant !
          profile.role = defaultRole !== "tenant" ? "technician" : "tenant";
        }

        if (!backendConfig.allowedRoles.includes(profile.role)) {
          return Promise.reject(
            `Only ${backendConfig.allowedRoles.join(",")} roles are allowed.`
          );
        }

        // Set the default profile layout if none (or empty) is provided
        if (!profile.layout || Object.entries(profile.layout).length === 0) {
          try {
            // Use the user role as a profile layout
            profile.layout = require(`../assets/layout-${profile.role}.json`);
          } catch (e) {
            // Else usr the default profile
            profile.layout = require(`../assets/layout-default.json`);
          }
        }

        return profile;
      })
      .catch((error: any) => {
        if (error.response.status === 404) {
          // fixme: test only while API is not yet implemented!
          console.error(`GET ${backendConfig.profileEndpoint}, 404, WTF!`);
          return Promise.resolve(fakeProfile);
        }
      })
  );
}

export function getUserAllowedCompanyInventories(): Promise<AxiosResponse> {
  // console.log("[user] inventory companie");
  return _axios.get(`${backendConfig.InventoryCompanyClientsEndpoint}`);
}

// prefixed function name with underscore because update and delete are reserved word in javascript
export function _update(user: any) {
  return _axios.put(`/users/${user.id}`, user);
}

export function _delete(id: number) {
  return _axios.delete(`/users/${id}`);
}
