import { format } from "date-fns";
import { inventoryService } from "@/_services";
import { InventoryState, InventoryType } from "@/_services/inventory.service";
import { equipmentService } from "@/_services";
import { router } from "@/_helpers";
import moment from "moment-timezone";

const equipmentState = ["Neuf", "Bon", "Moyen", "Dégradé", "Hors service"];

const state = {
  status: "",
  items: [],
  itemsCount: 0,
  itemsFreshness: 0,
  equipments: [],
  companyInventoryForms: {},
  companyInventoryFreshness: {},
};

const actions = {
  addEquipment({ dispatch, commit, rootState }, equipment) {
    commit("addEquipment", equipment);
  },

  updateEquipment({ dispatch, commit, rootState }, payload) {
    commit("updateEquipment", payload);
  },
  async getInventory({ dispatch, commit, rootState }, premiseId, inventoryId) {
    try {
      commit("getAllRequest");
      const equipments = await equipmentService.getAll({
        premiseId: premiseId,
        inventory: true,
      });
      console.log("[inventory] Got equipments:", equipments);
      equipments.forEach((equipment) => {
        equipment.inventoried = false;
        equipment.updated = false;
        equipment.displayed = true;
        equipment.inventory_type = null;
        equipment.inventory_state = InventoryState.NOT_VALIDATED;
        equipment.inventory_date = null;
        equipment.inventory_nb = inventoryId;
        equipment.inventory_author = rootState.account.user.company;
      });
      const inventoryData = await inventoryService.getInventory(premiseId);
      inventoryData.forEach((item) => {
        equipments.forEach((equipment) => {
          if (equipment.id === item.equipment_id) {
            equipment.inventory_id = item.id;
            equipment.inventoried = false;
          }
        });
      });
      commit("getAllEquipmentsSuccess", equipments);
      console.log("[inventory] Got last inventory:", inventoryData);
      commit("getAllSuccess", inventoryData);
      dispatch("alert/success", router.app.$t("equipments.ok_message"), {
        root: true,
      });

      inventoryData.forEach((inventory) => {
        dispatch("UpdateEquipmentInventory", inventory);
      });
    } catch (error) {
      commit("getAllFailure", error);
      if (error && error !== "Unauthorized") {
        dispatch("alert/error", error, { root: true });
      } else {
        dispatch("account/userDenied", "Equipments", { root: true });
      }
    }
  },
  UpdateEquipmentInventory({ state, commit }, inventory) {
    if (state.equipments.length === 0) {
      return;
    }
    commit("UpdateEquipmentInventory", inventory);
  },
  flushInventory({ state, dispatch, commit, rootState, rootGetters }) {
    commit("flushRequest");
    // console.log("[Ibrnyoty ] Equipments: ", state.equipments);
    state.equipments.forEach((eqp) => {
      /*
      console.log(eqp.name);
      console.log(" ", eqp.inventoried);
      console.log(" ", eqp.present);
      console.log(" ", eqp.updated);
      console.log(" ", eqp.new);
      // if (!eqp.inventoried) continue;
       */
      if (!eqp.new && !eqp.id) {
        eqp.new = true;
      }

      eqp.metadata = {
        ...eqp.metadata,
        LVL: eqp.chosenLevel || eqp?.metadata?.LVL,
        ROOM: eqp.chosenRoom || eqp?.metadata?.ROOM,
        RNUMBER: eqp.chosenNumber || eqp?.metadata?.RNUMBER,
      };

      if (eqp.new) {
        // create an equipment, the equipment creation
        // will also allow to create the inventory at the same time.
        // Set the numerical value of the equipment type
        eqp.type = eqp.type_id;
        equipmentService.create(eqp);
        commit("updateLastInventoryDate", {
          equipmentName: eqp.name,
          date: eqp.inventory_date,
        });
      } else if (eqp.inventoried) {
        if (eqp.location_updated) {
          equipmentService.update(eqp.id, {
            location: eqp.location,
          });
        }
        const inventoryDate = format(new Date(), "yyyy-MM-dd");
        // if there's a problem, still send the metadata of the equipment
        let cleanMeta = { ...eqp.metadata };

        // Retrieve the form with the metadata field name
        const form = rootGetters["equipments/getEquipmentForm"](eqp.type_id);

        // found form with metadata
        if (form !== undefined && form !== null) {
          // reset before sending
          cleanMeta = {};
          form.fields.forEach((field) => {
            // As discussed, only copy existing metadata, drop unknown meta
            if (field.name in eqp.metadata) {
              cleanMeta[field.name] = eqp.metadata[field.name];
            } else {
              // put empty value to overwrite
              cleanMeta[field.name] = "";
            }
          });
        } else {
          // No form ? just copy
          cleanMeta = eqp.metadata;
        }

        // create an inventory
        const inventoryPayload = {
          type: eqp.inventory_type,
          state: InventoryState.NOT_VALIDATED,
          // Force the date to today prevent empty/old date
          date: inventoryDate,
          nb: eqp.inventory_nb,
          metadata: {
            ...cleanMeta,
            LVL: eqp.chosenLevel || eqp?.metadata?.LVL,
            ROOM: eqp.chosenRoom || eqp?.metadata?.ROOM,
            RNUMBER: eqp.chosenNumber || eqp?.metadata?.RNUMBER,
          },
        };
        if (inventoryDate === eqp.inventory_date) {
          inventoryService.updateInventory(eqp.inventory_id, inventoryPayload);
        } else {
          inventoryService.createInventory(eqp.id, inventoryPayload);
        }
        commit("updateLastInventoryDate", {
          equipmentName: eqp.name,
          date: inventoryDate,
        });
      }
    });
    // TODO : What to do ?!?
  },

  loadCompanyForm: ({ commit, dispatch }, companyId) => {
    console.log("Called fill inventory form with company", companyId);
    try {
      inventoryService
        .getCompanyForm(companyId)
        .then((response) => response.data)
        .then((forms) => {
          commit("SetCompanyForm", { companyId, forms });
        });
    } catch (e) {
      console.error("Have an error: ", e);
    }
  },
  getBusinessForm: ({ state }, { businessId, premisesCategory }) => {
    console.log("Fetching business form for: ", businessId);
    const inventoryList = state.companyInventoryForms[businessId];
    console.log("Originally found", inventoryList.length);
    console.log(premisesCategory);
    const filteredInventory = inventoryList.filter(
      (inventoryForm) =>
        inventoryForm.inventory_classification.length === 0 ||
        inventoryForm.inventory_classification.includes(premisesCategory)
    );
    return filteredInventory;
  },

  clearAll({ commit }) {
    commit("clearAll");
  },
  clearForms({ commit }) {
    commit("clearForms");
  },
};

const mutations = {
  addEquipment(state, equipment) {
    // console.log("[inventory] adding equipment: ", equipment);
    state.equipments.push(equipment);
  },
  updateEquipment(state, payload) {
    // console.log("[inventory] updating equipment: ", payload.id, payload);
    // Nothing to do... update is automatic!
    const found = state.equipments.find((item) =>
      payload?.id
        ? item.id === payload.id
        : item.type_id === payload.type_id && item.type === payload.type
    );
    if (found) {
      // console.log("[inventory] found!");
      found.updated = true;
      found.name = payload.name;
      found.location = payload.location;
      found.chosenLevel = payload.chosenLevel;
      found.chosenRoom = payload.chosenRoom;
      found.chosenNumber = payload.chosenNumber;
    }
  },
  updateLastInventoryDate(state, { equipmentName, date }) {
    const found = state.equipments.find((item) => item.name === equipmentName);
    if (found) {
      found.inventory_date = date;
    }
  },
  UpdateEquipmentInventory(state, inventory) {
    const found = state.equipments.find(
      (item) => item.id === inventory.equipment_id
    );
    // console.log("[inventory] Equipment to up found ? ", found.name, inventory);
    if (found) {
      if (
        found.inventory_date === undefined ||
        found.inventory_date === null ||
        found.inventory_date <= inventory.date // update if same date
      ) {
        found.inventory_date = inventory.date;
        found.inventory_type = inventory.type;
        // update the metadata with object expansion
        // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Op%C3%A9rateurs/Syntaxe_d%C3%A9composition
        if (inventory.metadata) {
          found.metadata = { ...found.metadata, ...inventory.metadata };
          found.chosenLevel = inventory.metadata?.LVL;
          found.chosenRoom = inventory.metadata?.ROOM;
          found.chosenNumber = inventory.metadata?.RNUMBER;
        }
        // update display state
        switch (inventory.type) {
          case InventoryType.DELETED:
          case InventoryType.MISSING:
            console.log("Undisplay");
            found.displayed = false;
            break;
          default:
            found.displayed = true;
        }
      }
    }
  },
  clearAll(state) {
    state.status = "";
    state.items = [];
    state.itemsCount = 0;
    state.itemsFreshness = 0;
  },
  clearForms(state) {
    state.companyInventoryForms = {};
    state.companyInventoryFreshness = {};
  },
  getAllRequest(state) {
    state.status = "loading";
  },
  getAllSuccess(state, items) {
    state.status = "success";
    state.items = items;
    state.itemsCount = items.length;
    state.itemsFreshness = Date.now();
  },
  getAllEquipmentsSuccess(state, items) {
    state.status = "equipments";
    state.equipments = items.map((equipment) => {
      const existing = state.equipments.find(
        (item) => item.id === equipment.id
      );
      if (!existing) {
        return equipment;
      }
      return existing.updated ? existing : equipment;
    });
  },
  getAllFailure(state, error) {
    state.status = "error";
    state.error = error;
  },
  flushRequest(state) {
    state.status = "flush";
    state.error = null;
  },
  SetCompanyForm: (state, { companyId, forms }) => {
    state.companyInventoryForms[companyId] = forms;
    state.companyInventoryFreshness[companyId] = Date.now();
  },
};

const getters = {
  displayedEquipments: (state) => {
    // console.log("[Vue inventory]", state.equipments.filter((eqp) => eqp.displayed));
    return state.equipments;
  },
  // eslint-disable-next-line no-unused-vars
  getUsage: (state, getters) => (usage) => {
    return [
      router.app.$t("inventory.usage.1"),
      router.app.$t("inventory.usage.2"),
      router.app.$t("inventory.usage.3"),
    ][usage - 1];
  },
  // eslint-disable-next-line no-unused-vars
  getInventoryType: (state, getters) => (type) => {
    return [
      router.app.$t("inventory.type.1"),
      router.app.$t("inventory.type.2"),
      router.app.$t("inventory.type.3"),
      router.app.$t("inventory.type.4"),
    ][type - 1];
  },
  getEquipments: (state, getters) => {
    return state.equipments;
  },
};

export const inventory = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
