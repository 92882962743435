<template>
  <viz-base-tile :id="id" :data="data" :premised="premised">
    <template #name>
      <cite>I am a Map widget #{{ _uid }} : {{ data }}</cite>
    </template>

    <template #body>
      <div style="height: 100%; width: 100%">
        <b-button variant="primary" class="m-2" @click="getLocation()">
          Update my position
        </b-button>
        <l-map
          :id="`map-${_uid}`"
          ref="myMap"
          style="height: 320px; width: 100%"
          :zoom="zoom"
          :center="center"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          @update:bounds="boundsUpdated"
        >
          <l-tile-layer :url="url"></l-tile-layer>

          <l-circle-marker
            :lat-lng="myPosition.position"
            :fill="myPosition.fill"
            :radius="myPosition.radius"
            :color="myPosition.color"
          >
            <l-tooltip>Je suis là ;)</l-tooltip>
            <l-popup content="C'est moi !"></l-popup>
          </l-circle-marker>

          <l-marker :lat-lng="marker">
            <l-tooltip
              >Je suis chez <br />
              DPM Technologies <br />
              à Valence :-)
            </l-tooltip>
            <l-popup content="Coucou!"></l-popup>
          </l-marker>

          <l-marker
            v-for="item in markers"
            :key="item.id"
            :lat-lng="item.latlng"
          >
            <l-popup :content="item.content"></l-popup>
          </l-marker>
        </l-map>
      </div>
    </template>
  </viz-base-tile>
</template>

<script>
import { locationError } from "../../_helpers";
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LPopup,
  LCircleMarker,
} from "vue2-leaflet";
import { latLng, Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { mapState } from "vuex";

// this part resolve an issue where the markers would not appear
// see: https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
// javascript:
delete Icon.Default.prototype._getIconUrl;
// typescript:
// type D = Icon.Default & {
//   _getIconUrl?: string;
// };
// delete (Icon.Default.prototype as D)._getIconUrl;

Icon.Default.mergeOptions({
  iconUrl: require("../../../public/img/map/marker-icon.png"),
  iconRetinaUrl: require("../../../public/img/map/marker-icon-2x.png"),
  shadowUrl: require("../../../public/img/map/marker-shadow.png"),
});

export default {
  name: "VizMap",
  components: {
    VizBaseTile: () => import("./BaseTile"),
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    LCircleMarker,
  },
  props: {
    id: {
      type: String,
      default: "1",
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    premised: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Basic map features
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 12,
      center: [44.918709, 4.914901],
      marker: [44.918709, 4.914901],
      markers: [],
      bounds: null,

      // Geolocation features
      geolocationSupported: "geolocation" in navigator,
      myPosition: {
        position: latLng(44.9, 4.9),
        fill: false,
        radius: 4,
        color: "red",
      },
    };
  },
  computed: {
    ...mapState({
      chosenPremise: (state) => state.premises.selected,
    }),
  },
  watch: {
    // Changed the premise to inventory
    chosenPremise: function (newVal, oldVal) {
      if (!this.premised) {
        return;
      }

      if (newVal === null) {
        // No more selected premise
        // console.log("[map] no more selected premise");
        // this.clearAll();
      } else {
        // Newly selected premise
        // Get all the available markers for the selected premise
        // console.log("[map] fetching...");
        // this.getAll(this.chosenPremise.name);
      }
    },
  },
  created() {
    console.log(
      `[map] Premised: ${this.premised}, chosen: '${
        this.chosenPremise && this.chosenPremise.name
      }'`
    );

    if (!this.premised || (this.premised && this.chosenPremise)) {
      // console.log("[map] Getting all markers");
      // todo: Get all the available markers
      // this.getAll();
    }
  },
  mounted() {
    if (this.premised && !this.chosenPremise) {
      return;
    }

    // console.log("[map] get my current location");
    window.setTimeout(() => {
      // Get my location
      this.getLocation();
    }, 5000);

    if (this.data && this.data.markers) {
      this.data.markers.forEach((marker, index) => {
        window.setTimeout(() => {
          this.markers.push({
            id: marker.id,
            latlng: marker.latlng ? marker.latlng : [marker.lat, marker.lng],
            content: marker.content,
          });
        }, index * 1000);
      });
    }
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    getLocation() {
      // console.log("[map] getLocation");
      if (this.geolocationSupported) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          locationError,
          {
            enableHighAccuracy: false,
            timeout: 15000,
            maximumAge: 0,
          }
        );
      } else {
        // console.log("Geo Location is not supported by the browser");
      }
    },
    showPosition(position) {
      // Update my position
      this.myPosition.position = latLng(
        position.coords.latitude,
        position.coords.longitude
      );
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Map"
  },
  "fr": {
    "title": "Carte"
  }
}
</i18n>
